import React from 'react';
import { useRouteMatch } from 'react-router';
import {
  Breadcrumb,
  ProviderSelecionarPublico,
  TitleDescription,
  LoadingIndicator,
  ProviderXlsxJson,
} from '@digi-tim-19/components';

import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { Form } from '../FormManager/FormManage';
import { useIncentiveCampaign } from '../../../hooks/incentiveCampaign/useIncentiveCampaign';

export const CampanhaIncentivoEditar = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const incentiveCampaign = useIncentiveCampaign(params?.id, appendToFragment);

  const configPage = {
    pageName: 'Campanhas e resultados',
    icon: 'trophy',
    description: 'Cadastramento de campanhas de incentivo e resultados.',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      { title: 'Gerenciar conteúdo' },
      {
        title: 'Campanhas de Incentivo',
        link: routes.campanhasIncentivoListar.mount(),
      },
      { title: 'Campanhas e resultados' },
    ],
    routeList: routes.campanhasIncentivoListar.mount(),
  };

  return (
    <PageTemplate>
      <>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />

        {incentiveCampaign.loading ? (
          <LoadingIndicator />
        ) : (
          <ProviderSelecionarPublico>
            <ProviderXlsxJson>
              <Form initialValues={incentiveCampaign.result || {}} />
            </ProviderXlsxJson>
          </ProviderSelecionarPublico>
        )}
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  kpis
  campaignParams
  boosters
  channel
  campaignType
  subChannel
  campaignPeriod
  periodOption
  category
  availableAtRegions
  availableAtChannels
  availableAtRoleGroups
  title
  description
  schedule
  videoLink
  enableSocialInteraction
  cardImage
  bannerContent
  contestationPeriod
  ranking
  results
  regulation
  presentation
  invoiceOrigin
  faq
  status {
    _id
  }
  validity {
    start
    end
  }
  files {
    title
    _id
    downloadUrl
    createdAt
  }
  prizeType
`;
