import React, { useState, useEffect } from 'react';
import { Breadcrumb, TitleDescription, Table, LoadingIndicator, Button } from '@digi-tim-19/components';
import { clickDownload } from '@digi-tim-19/utils/build';
import styled from 'styled-components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumIncentiveCampaignStatus,
  SortFindManyIncentiveCampaignInput,
  ValidityEnum,
} from '../../../autogenerated/client/types';

import { CampanhaIncentivoListWrapper, Search, CleanButton } from './styles';
import { columns } from './columns';
import { formatDate } from '@digi-tim-19/utils/build';
import { Badge, Select, Col, Row, Modal } from 'antd';
import { sortBy } from 'lodash';
import { downloadXLSX } from '../../../utils/downloadXlsx';
const { Option } = Select;

const LegendsWrappper = styled.div`
  display: flex;
  width: max-content;
  justify-content: space-between;
  gap: 10px;
`;

const configPage = {
  pageName: 'Distribuição de TIMCOINS',
  icon: 'trophy',
  description: 'Resumo da distribuição de TIMCOINS.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo' },
    { title: 'Campanhas de Incentivo', link: routes.campanhasIncentivoListar.mount() },
    { title: 'Distribuição de TIMCOINS' },
  ],
};

export const DistribuicaoPontos = () => {
  const [page, setPage] = useState(1);

  const [filterMatricula, setFilterMatricula] = useState<string>();
  const [filterCPF, setFilterCPF] = useState<string>();
  const [filterName, setFilterName] = useState<string>();

  const [matricula, setMatricula] = useState<string>();
  const [cpf, setCPF] = useState<string>();
  const [userName, setUserName] = useState<string>();

  const getIncentiveCampaign = useClient('IncentiveCampaignPagination');
  const getUserCreditOperation = useClient('ExtractUserCreditPoints');
  const downloadtUserCreditPoints = useClient('DownloadtUserCreditPoints');
  const [dataUsers, setDataUsers] = useState<any[]>([]);
  const [totalResults, setTotalResults] = useState(0);
  const [treeOptionsIncentiveCampaings, setTreeOptionsIncentiveCampaings] = useState<any[]>([]);
  const [incentiveCampaingsSelected, setIncentiveCampaingsSelected] = useState([]);

  useEffect(() => {
    getIncentiveCampaign.fetch({
      appendToFragment: appendToFragmentCampaign,
      variables: {
        perPage: 500,
        page: 1,
        sort: SortFindManyIncentiveCampaignInput.IdDesc,
        filter: {
          status: EnumIncentiveCampaignStatus.Closed,
          validityEnum: ValidityEnum.Any,
        },
      },
    });
  }, []);

  useEffect(() => {
    getUserCreditOperation.fetch({
      appendToFragment: appendToFragmentPointsUsers,
      variables: {
        incentiveCampaignIds: incentiveCampaingsSelected?.length ? incentiveCampaingsSelected : undefined,
        matricula: matricula,
        cpf: cpf?.replace(/[^0-9]/g, ''),
        userName: userName,
      },
    });
  }, [matricula, cpf, userName, incentiveCampaingsSelected]); //eslint-disable-line

  useEffect(() => {
    if (filterMatricula) {
      const matriculaValue = filterMatricula?.length >= 7 ? filterMatricula : undefined;

      if (matriculaValue) setMatricula(matriculaValue?.toUpperCase());

      if (filterMatricula?.length === 0) setMatricula(undefined);
    } else setMatricula(undefined);
  }, [filterMatricula]);

  useEffect(() => {
    if (filterCPF) {
      const cpfValue = filterCPF?.length >= 11 ? filterCPF : undefined;

      if (cpfValue) setCPF(cpfValue);

      if (filterCPF?.length === 0) setCPF(undefined);
    } else setCPF(undefined);
  }, [filterCPF]);

  useEffect(() => {
    if (filterName) {
      const userNameValue = filterName?.length >= 3 ? filterName : undefined;

      if (userNameValue) setUserName(userNameValue);

      if (filterName?.length === 0) setUserName(undefined);
    } else setUserName(undefined);
  }, [filterName]);

  useEffect(() => {
    if (getIncentiveCampaign.result) {
      if (getIncentiveCampaign.result?.items) {
        const items = getIncentiveCampaign.result?.items?.map((campaign: any) => {
          return {
            label: campaign?.title,
            value: campaign?._id,
          };
        });

        const itemsSorted = sortBy(items, 'title');
        setTreeOptionsIncentiveCampaings(itemsSorted);
      }
    } else {
      setTreeOptionsIncentiveCampaings([]);
    }
  }, [getIncentiveCampaign.result]);

  useEffect(() => {
    if (getUserCreditOperation.result) {
      setTotalResults(getUserCreditOperation.result.length);
      setDataUsers(
        getUserCreditOperation.result?.map((user: any) => {
          return {
            key: user?._id,
            name: user?.userInformation?.name,
            matricula: user?.userInformation?.matricula,
            cpf: user?.userInformation?.CPF,
            channel: user?.userInformation?.channelName,
            region: user?.userInformation?.regionName,
            receivedPoints: user?.value,
            campaignName: user?.creditOriginCampaignData?.campaignTitle,
            pointsCreditDate: formatDate(user?.creditOriginCampaignData?.addedAt || user?.createdAt, 'DD/MM/YYYY'),
            pointsCreditWarningDate: formatDate(user?.creditOriginCampaignData?.warningAt, 'DD/MM/YYYY'),
            op: user.op,
            isRemoved: !!user?.userInformation?.isRemoved,
          };
        }),
      );
    } else {
      resetResult();
    }
  }, [getUserCreditOperation.result]);

  function resetResult() {
    setDataUsers([]);
    setTotalResults(0);
  }

  const resetFilters = () => {
    setIncentiveCampaingsSelected([]);
    setFilterMatricula(undefined);
    setFilterCPF(undefined);
    setFilterName(undefined);
  };

  const onClickDownload = async () => {
    function getColorByOp(op: string) {
      switch (op) {
        case 'credit':
          return 'Crédito';
        case 'debit':
          return 'Pontos Utilizados';
        case 'expired':
          return 'Pontos Expirados';
        default:
          return 'Processado';
      }
    }

    function getOriginName(item: any): string {
      if (item.op === 'debit') return 'Pontos Utilizados';

      if (item.op === 'expired') return 'Pontos expirados';

      return item?.creditOriginCampaignData?.campaignTitle || '';
    }

    const dataSerialized = getUserCreditOperation?.result?.map((item) => ({
      origem: getOriginName(item),
      nome_participante: item?.userInformation?.name || '',
      matricula: item?.userInformation?.matricula || '',
      CPF: item?.userInformation?.CPF || '',
      canal: item?.userInformation?.channelName || '',
      regional: item?.userInformation?.regionName || '',
      pontos: item?.value || 0,
      // removido: !!item?.userInformation?.isRemoved ? 'Sim' : 'Não',
      operacao: getColorByOp(item?.op || ''),
      data: formatDate(item?.createdAt, 'DD/MM/YYYY'),
      // data_aceite_pontos: formatDate(item?.creditOriginCampaignData?.warningAt, 'DD/MM/YYYY'),
    })) as any[];

    if (!dataSerialized?.length) {
      Modal.error({
        content: 'Não foi possível concluir o download, tente novamente mais tarde.',
      });

      return;
    }

    const schema = Object.keys(dataSerialized[0]).reduce((acc, key: any) => {
      acc[key] = { label: key };
      return acc;
    }, {} as Record<string, any>);

    downloadXLSX('Distribuição de TIMCOINS', schema, dataSerialized);

    Modal.success({
      content: 'Download concluído com sucesso.',
    });
  };

  return (
    <PageTemplate>
      <CampanhaIncentivoListWrapper>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />

        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Row gutter={[16, 16]}>
            <Col sm={12} xs={24}>
              <div style={{ position: 'relative', width: '100%' }}>
                <Select
                  mode="multiple"
                  style={{ width: '100%' }}
                  placeholder="Campanhas"
                  onChange={(e: any) => {
                    setPage(1);
                    setIncentiveCampaingsSelected(e);
                  }}
                  filterOption={(value, option) =>
                    Boolean(option.props?.title?.toLowerCase().includes(value.toLowerCase()))
                  }
                  showSearch={true}
                >
                  {treeOptionsIncentiveCampaings.map((item) => (
                    <Option key={item.value} value={item.value} title={item.label}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
                <svg
                  style={{ position: 'absolute', top: '25%', right: '10px' }}
                  viewBox="64 64 896 896"
                  focusable="false"
                  className=""
                  data-icon="search"
                  width="1em"
                  height="1em"
                  fill="#00508c"
                  fontSize="18px"
                  aria-hidden="true"
                >
                  <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                </svg>
              </div>
            </Col>
            <Col sm={12} xs={24}>
              <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', marginTop: '3px' }}>
                <label>Para visualização na tela selecione pelo menos 1 campanha.</label>
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col sm={5} xs={24}>
              <Search
                placeholder="Matrícula"
                style={{ width: 200 }}
                value={filterMatricula}
                onChange={(e) => {
                  setFilterMatricula(e.target.value);
                  setPage(1);
                }}
              />
            </Col>
            <Col sm={5} xs={24}>
              <Search
                placeholder="CPF"
                style={{ width: 200 }}
                value={filterCPF}
                onChange={(e) => {
                  setFilterCPF(e.target.value);
                  setPage(1);
                }}
              />
            </Col>
            <Col sm={6} xs={24}>
              <Search
                placeholder="Nome do participante"
                style={{ width: 200 }}
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  setPage(1);
                }}
              />
            </Col>
            <Col sm={4} xs={24}>
              <Button style={{ width: '100%' }} onClick={onClickDownload} loading={downloadtUserCreditPoints.loading}>
                Download
              </Button>
            </Col>
            <Col sm={4} xs={24}>
              <CleanButton style={{ width: '100%' }} onClick={() => resetFilters()}>
                Limpar filtros
              </CleanButton>
            </Col>
          </Row>

          {getUserCreditOperation.loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <Table
                columns={columns}
                dataSource={dataUsers}
                pagination={{
                  pageSize: 10,
                  current: page,
                  total: totalResults!,
                  onChange: (e) => setPage(e),
                }}
              />
              {dataUsers.length > 0 && (
                <LegendsWrappper>
                  <Badge status="success" text="Crédito" />
                  <Badge status="error" text="Débito" />
                  <Badge status="warning" text="Expirado" />
                </LegendsWrappper>
              )}
              <div>Para descobrir o débito faça o filtro por usuário</div>
            </>
          )}
        </div>
      </CampanhaIncentivoListWrapper>
    </PageTemplate>
  );
};

const appendToFragmentPointsUsers = `
  value
  op
  creditOriginCampaignData {
    campaignTitle
    addedAt
    warningAt
  }
  userInformation{
    name
    CPF
    matricula
    email
    channelName
    regionName
    isRemoved
  }
`;

const appendToFragmentCampaign = `
count
pageInfo {
  itemCount
}
items {
  _id
  title
  cardImageFile {
    signedUrl
  }
  validity {
    start
    end
  }
  status {
    _id
    label
    value
  }
}
`;
