import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  Button,
  Table,
  TitleDescription,
  useXlsxJsonActions,
  ProviderXlsxJson,
} from '@digi-tim-19/components';
import { ApuracaoCampanhaListWrapper, ContainerButtons } from './styles';
import { routes } from '../../../config/routes';
import { useInvoiceOriginOptions } from '../useInvoiceOriginOptions';
import { message, Modal, TreeSelect, Upload } from 'antd';
import { useInMemoPagination } from '../../../hooks/useInMemoPagination';
import { padStart } from 'lodash';
import { EnumIncentiveCampaignStatus, ValidityEnum } from '../../../autogenerated/client/types';
import { useClient } from '../../../autogenerated/client/client';

type JSONData = {
  _id: string;
  [key: string]: string;
};

const configPage = {
  pageName: 'Apuração Campanha de incentivo',
  icon: 'trophy',
  description: 'Apuração de TIMCOINS para campanhas de incentivo e resultados.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo' },
    {
      title: 'Campanhas de Incentivo',
      link: routes.campanhasIncentivoListar.mount(),
    },
    { title: 'Apuração' },
    { title: 'Listar' },
  ],
};

export const ApuracaoIncentivo = () => {
  return (
    <ProviderXlsxJson>
      <ApuracaoIncentivoForm />
    </ProviderXlsxJson>
  );
};

export const ApuracaoIncentivoForm = () => {
  const invoiceOrigins = useInvoiceOriginOptions();
  const [fileList, setFileList] = React.useState<any[]>([]);
  const [loadingUpload, setLoading] = React.useState(false);
  const getIncentiveCampaign = useClient('IncentiveCampaignPagination', {
    fetchOnMount: true,
    variables: {
      page: 1,
      perPage: 25,
      filter: {
        OR: [
          { status: EnumIncentiveCampaignStatus.InProgress },
          { status: EnumIncentiveCampaignStatus.InVerification },
        ],
        validityEnum: ValidityEnum.Any,
      },
    },
    appendToFragment: `
      items {
        _id
        title
      }
    `,
  });

  const campaignOptions = React.useMemo(() => {
    if (!getIncentiveCampaign.data) {
      return [];
    }
    return (
      getIncentiveCampaign.data?.items?.map((el: any) => ({
        label: el.title,
        value: el._id,
      })) ?? []
    );
  }, [getIncentiveCampaign.data]);

  const xlsxJsonActions = useXlsxJsonActions();

  const [fullData, setFullData] = React.useState<JSONData[]>([]);

  React.useEffect(() => {
    const parsedData: JSONData[] = [];
    const rawData = xlsxJsonActions?.state?.json ?? [];

    if (!rawData.length && xlsxJsonActions.state.file !== null) {
      message.error(
        'Não foi possível ler os dados. certifique-se de que o arquivo está no formato correto e sem abas ocultas',
      );
      return;
    }

    if ((!rawData[0]?.CPF || !rawData[0]?.PONTOS) && xlsxJsonActions.state.file !== null && rawData.length > 0) {
      message.error('Formato de dados inválido. Verifique se as colunas estão de acordo com o modelo');
      return;
    }

    const errors: JSONData[] = [];

    rawData.forEach((el: any, i: number) => {
      let data = {
        ...el,
        line: i + 2,
        CPF: padStart(el.CPF, 11, '0'),
        STATUS: 'Pendente',
      };

      if (!el.CPF || el.CPF === '' || el.CPF === '00000000000') {
        data.CPF = '';
        data.STATUS = 'ERRO';
        data.DETALHES = 'CPF inválido';
        errors.push(data);
      }

      if (!el.PONTOS || Number.isNaN(Number(el.PONTOS)) || Number(el.PONTOS) <= 0) {
        data.STATUS = 'ERRO';
        data.DETALHES = 'TIMCoins inválidos';
        errors.push(data);
      }

      parsedData.push(data);
    });

    if (errors.length) {
      message.error('Foram encontrados erros na planilha. Verifique os detalhes na tabela abaixo');
      setFullData(errors);
    } else {
      setFullData(parsedData);
    }
  }, [xlsxJsonActions.state.json]);

  const { dataToRender, pagination } = useInMemoPagination({
    data: fullData,
    perPage: 15,
  });

  const [campaignId, setCampaignId] = React.useState<string | null>(null);
  const [invoiceOriginId, setInvoiceOriginId] = React.useState<string | null>(null);

  const incentiveCampaignPay = useClient('IncentiveCampaignPay');
  const loading = loadingUpload || getIncentiveCampaign.loading || incentiveCampaignPay.loading;

  const handlePayment = () => {
    if (loading || !campaignId || !invoiceOriginId || !fullData.length) {
      return;
    }
    incentiveCampaignPay
      .fetch({
        variables: {
          campaignId,
          invoiceOriginId,
          data: fullData,
        },
      })
      .then((cx) => {
        if (!Array.isArray(cx.result)) {
          return;
        }
        setFullData(cx.result as JSONData[]);
      });
  };

  const disabled = loading || !campaignId || !invoiceOriginId || !fullData.length;

  return (
    <PageTemplate>
      <ApuracaoCampanhaListWrapper>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />
        <ContainerButtons>
          <Button
            onClick={() => {
              window.open('/modelo_upload_pontos.xlsx');
            }}
          >
            Baixar Modelo
          </Button>

          <Upload
            accept=".xlsx"
            multiple={false}
            showUploadList={false}
            fileList={fileList}
            onRemove={() => {
              setFileList([]);
              xlsxJsonActions.reset();
            }}
            openFileDialogOnClick={true}
            customRequest={(op) => {
              setLoading(true);
              setFileList([op.file]);
              xlsxJsonActions.setState('file', op.file);
              setLoading(false);
            }}
          >
            <Button icon="upload">Subir planilha</Button>
          </Upload>

          <div style={{ marginLeft: 'auto' }}>
            <TreeSelect
              style={{ width: '250px', marginRight: '15px' }}
              dropdownStyle={{ maxHeight: '40vh' }}
              treeData={invoiceOrigins}
              placeholder="Origem Fatura"
              onChange={(e: any) => {
                setInvoiceOriginId(e);
              }}
            />
            <TreeSelect
              style={{ width: '250px', marginRight: '15px' }}
              dropdownStyle={{ maxHeight: '40vh' }}
              treeData={campaignOptions}
              placeholder="Campanhas disponíveis"
              onChange={(e: any) => {
                setCampaignId(e);
              }}
            />
            <Button
              onClick={() => {
                Modal.confirm({
                  centered: true,
                  width: '50%',
                  title: `Confirmar upload e distribuição de TIMCOINS`,
                  content: <p>{`Verifique os dados na tabela antes de dar prosseguimento ao pagamento.`}</p>,
                  onOk: () => {
                    handlePayment();
                  },
                });
              }}
              disabled={disabled}
            >
              Distribuir TIMCOINS
            </Button>
          </div>
        </ContainerButtons>
        {loading && <span>Carregando...</span>}
        <Table
          columns={[
            {
              title: 'LINHA',
              dataIndex: 'line',
              key: 'line',
            },
            {
              title: 'CPF do participante',
              dataIndex: 'CPF',
              key: 'CPF',
            },
            {
              title: 'TIMCoins',
              dataIndex: 'PONTOS',
              key: 'PONTOS',
            },
            {
              title: 'Status do pagamento',
              dataIndex: 'STATUS',
              key: 'STATUS',
            },
            {
              title: 'Detalhamento',
              dataIndex: 'DETALHES',
              key: 'DETALHES',
            },
          ]}
          dataSource={dataToRender}
          pagination={pagination}
        />
      </ApuracaoCampanhaListWrapper>
    </PageTemplate>
  );
};
