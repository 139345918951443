import { Checkbox, Icon, Modal, Select, Table, message } from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import React, { useEffect, useState } from 'react';
import { useClient } from '../../../autogenerated/client/client';
import moment from 'moment';
import { LoadingContainer } from './styles';
import { LoadingIndicator } from '@digi-tim-19/components';

const { Option } = Select;

interface Props {
  dataSource?: any;
  onSelected?: (ids: string[]) => void;
  pagination: PaginationConfig;
  invoiceOriginList?: any;
  refetch?: () => void;
}

interface ModalProps {
  userChallengeId: string;
  invoiceOrigins?: any;
  refetch?: () => void;
}

const ModalWalletPoints = ({ userChallengeId, invoiceOrigins, refetch }: ModalProps) => {
  const userRegisterCreditPoints = useClient('UserCreditChallengePointsAction', {
    afterMutate: /UserChallengePoints/,
  });
  const [open, setOpen] = React.useState<boolean>(false);
  const [walletId, setWalletId] = React.useState<any>(undefined);

  return (
    <React.Fragment>
      <Icon type="dollar" onClick={() => setOpen(true)} />
      <Modal
        okButtonProps={{
          disabled: invoiceOrigins?.length === 0 || !walletId,
        }}
        title="Liberação de TIMCOINS"
        visible={open}
        okText="Liberar TIMCOINS"
        cancelText="Cancelar"
        onOk={() => {
          userRegisterCreditPoints
            .fetch({
              variables: {
                userChallengeId,
                walletId,
              },
            })
            .then((response) => {
              if (response?.errors && response?.errors?.length > 0) {
                message.error(response?.errors[0]);
              } else {
                message.success(`Liberação de TIMCOINS efetuada com sucesso`);
                if (refetch) refetch();
                setOpen(false);
              }
            });
        }}
        onCancel={() => setOpen(false)}
        confirmLoading={userRegisterCreditPoints.loading}
      >
        {invoiceOrigins?.length === 0 ? (
          <LoadingContainer>
            <LoadingIndicator />
          </LoadingContainer>
        ) : (
          <React.Fragment>
            <Select
              style={{ width: '100%' }}
              placeholder="ORIGEM DA FATURA"
              onChange={(value: any) => setWalletId(value)}
            >
              {invoiceOrigins?.map((option: any) => {
                return (
                  <Option key={option._id} value={option._id}>
                    {option?.invoiceOriginData?.name ? `${option?.invoiceOriginData?.name} - ` : ''}
                    {option?.POIdentifier}
                  </Option>
                );
              })}
            </Select>
          </React.Fragment>
        )}
      </Modal>
    </React.Fragment>
  );
};

export const TableChallengePoints = (props: Props) => {
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    if (props.onSelected) props.onSelected(selected);
  }, [selected]);

  return (
    <Table
      columns={[
        {
          title: (
            <div style={{ display: 'flex', gap: '8px' }}>
              <Checkbox
                onChange={(evt) => {
                  if (!evt.target.checked) {
                    setSelected([]);
                  } else {
                    setSelected(props.dataSource.map((el: any) => `${el._id}`));
                  }
                }}
              />
              <p>Selecione</p>
            </div>
          ),
          render: (_: any, record: any) => (
            <Checkbox
              checked={selected.includes(`${record._id}`)}
              onChange={() => {
                setSelected((prev) => {
                  const userId = record._id;
                  if (!userId) return prev;
                  return prev.includes(userId) ? prev.filter((user) => user !== userId) : [...prev, userId];
                });
              }}
            />
          ),
        },
        {
          title: 'ID',
          dataIndex: 'challengeNumericId',
          key: 'challengeNumericId',
          render: (_: any, record: any) => <p>{record?.challengeNumericId}</p>,
        },
        {
          title: 'Desafio',
          dataIndex: 'challengeData',
          key: 'challengeData',
          render: (_: any, record: any) => <p>{record?.challenge?.title}</p>,
        },
        {
          title: 'Usuário',
          dataIndex: 'userInformation',
          key: 'name',
          render: (_: any, record: any) => <p>{record?.userInformation?.name || ''}</p>,
        },
        {
          title: 'CPF',
          dataIndex: 'userInformation',
          key: 'cpf',
          render: (_: any, record: any) => <p>{record?.userInformation?.CPF || ''}</p>,
        },
        {
          title: 'Matrícula',
          dataIndex: 'userInformation',
          key: 'matricula',
          render: (_: any, record: any) => <p>{record?.userInformation?.matricula || ''}</p>,
        },
        {
          title: 'Canal',
          dataIndex: 'userInformation',
          key: 'channel',
          render: (_: any, record: any) => <p>{record?.userInformation?.channelName}</p>,
        },
        {
          title: 'Região',
          dataIndex: 'userInformation',
          key: 'region',
          render: (_: any, record: any) => <p>{record?.userInformation?.regionName}</p>,
        },
        {
          title: 'TIMCOINS',
          dataIndex: 'points',
          key: 'points',
          render: (text: any, record: any) => <p>{text}</p>,
        },
        {
          title: 'Data',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (text: string) => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
        },
        {
          title: 'Ações',
          key: 'action',
          render: (_: any, record: any) => (
            <ModalWalletPoints
              refetch={props?.refetch}
              userChallengeId={record.userChallengeId}
              invoiceOrigins={props?.invoiceOriginList || []}
            />
          ),
        },
      ]}
      dataSource={props?.dataSource ?? []}
      pagination={props?.pagination}
    />
  );
};
