import { Button, Input, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';
import { Select } from '@digi-tim-19/components';

type Booster = { _id: string; levelId?: string; factor: number };
interface Props {
  initial?: Booster[];
  options?: { label: string; value: string }[];
  onChange: (values: Booster[]) => void;
}

export const Boosters = (props: Props) => {
  const { initial = [], options = [], onChange } = props;
  const [state, setState] = useState<Booster[]>(() => (initial.length ? initial : [{ _id: uniqueId(), factor: 0 }]));

  useEffect(() => {
    if (state.length && onChange) onChange(state);
  }, [state]);

  const checked = (id: string) => {
    return state.map((el) => el._id).includes(id);
  };

  const onToogle = (id: string) => {
    setState((state) =>
      state.map((el) => el._id).includes(id) ? state.filter((el) => el._id !== id) : [...state, { _id: id, factor: 0 }],
    );
  };

  const onUpdate = (id: string, prop: keyof Booster, value: any) => {
    setState((state) => state.map((el) => (el._id !== id ? el : { ...el, [prop]: value })));
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
      }}
    >
      {options?.length === 0 ? (
        <span style={{ textAlign: 'center' }}>Não há níveis para cadastrar aceleradores</span>
      ) : (
        <React.Fragment>
          <Table
            key={'_id'}
            dataSource={state}
            pagination={false}
            columns={[
              {
                title: 'Níveis',
                render(_, { _id, levelId }) {
                  return <Select value={levelId} options={options} onChange={(el) => onUpdate(_id, 'levelId', el)} />;
                },
              },
              {
                title: 'Acelerador de TIMCOINS',
                render(_, { _id }) {
                  return (
                    <Input
                      placeholder="0"
                      disabled={!checked(_id)}
                      value={state.find((el) => el._id === _id)?.factor ?? 0}
                      onChange={(value) => onUpdate(_id, 'factor', value.target.value)}
                    />
                  );
                },
              },
              {
                title: 'Remover',
                render(_, { _id }) {
                  return <Button onClick={() => onToogle(_id)}>Remover</Button>;
                },
              },
            ]}
          />
          <Button onClick={() => onToogle(uniqueId())}>Adicionar acelerador</Button>
        </React.Fragment>
      )}
    </div>
  );
};
