import React from 'react';
import styled from 'styled-components';
import { Icon, Progress as AntdProgress } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-BR');
interface Props {
  description: string;
  tend: number;
  points: number;
  userBooster: number;
  lastUpdate: string;
  faixa1: any;
  faixa2: any;
  faixa3: any;
  eligibility: boolean;
}

export const CanvassRangeOfReach = (props: Props) => {
  const { description, tend, faixa1, faixa2, faixa3, lastUpdate, eligibility } = props;

  return (
    <CardContainer>
      <InfoContainer>
        <Title>{`FAIXA DE ATINGIMENTO`}</Title>
        <Description>{description}</Description>
        <Content>
          <IconContainer>
            <Icon type="user" style={{ fontSize: '24px' }} />
          </IconContainer>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {faixa1?.goal && (
              <ProgressContainer>
                <Progress percent={faixa1?.percent ?? 0} showInfo={false} />
                <PercentInfo>{`Faixa 1: ${faixa1.percent}% / ${faixa1.totalPercent}%`}</PercentInfo>
              </ProgressContainer>
            )}
            {faixa2?.goal && (
              <ProgressContainer>
                <Progress percent={faixa1?.percent > 100 ? faixa2?.percent : 0} showInfo={false} />
                <PercentInfo>{`Faixa 2: ${faixa1.percent}% / ${faixa2.totalPercent}%`}</PercentInfo>
              </ProgressContainer>
            )}
            {faixa3?.goal && (
              <ProgressContainer>
                <Progress
                  percent={faixa1?.percent > 100 && faixa2?.percent > 100 ? faixa3?.percent : 0}
                  showInfo={false}
                />
                <PercentInfo>{`Faixa 3: ${faixa1.percent}% / ${faixa3.totalPercent}%`}</PercentInfo>
              </ProgressContainer>
            )}
          </div>

          <EmojiContainer>
            <Icon type="smile" />
          </EmojiContainer>
        </Content>
        {tend ? <TrendContainer>{`TENDÊNCIA: ${tend}`}</TrendContainer> : null}
        <ResultInfoContainer>
          <ResultInfo>Resultado Parcial</ResultInfo>
          <ResultDate>{`Última atualização: ${moment(lastUpdate).format('DD/MM/YYYY')}`}</ResultDate>
        </ResultInfoContainer>
      </InfoContainer>
      <ExtraInfoContainer>
        <ExtraInfoBox>
          <ExtraInfoTitle>{`${
            eligibility && !(faixa3?.premio || faixa2?.premio || faixa1?.premio)
              ? 'CRITÉRIO DE ELEGIBILIDADE'
              : 'TIMCOINS POR ATINGIMENTO'
          }`}</ExtraInfoTitle>
          <ExtraInfoPoints>{faixa3?.premio || faixa2?.premio || faixa1?.premio}</ExtraInfoPoints>
        </ExtraInfoBox>
      </ExtraInfoContainer>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #3939e1;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  border-top-right-radius: 24px;
`;

const InfoContainer = styled.div`
  padding: 16px 22px;
  width: 100%;
  background-color: #f5f5f5;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;

  @media (max-width: 630px) {
    width: 100%;
    padding: 16px;
  }
`;

const ExtraInfoContainer = styled.div`
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
`;

const ExtraInfoBox = styled.div<{ first?: boolean }>`
  margin: auto;
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #f5f5f5;
  margin-top: 5px;
`;

const ExtraInfoTitle = styled.h3`
  margin: 0;
  text-align: center;
  font-size: 12pt;
  color: white;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 9pt;
  }
`;

const ExtraInfoPoints = styled.span`
  font-size: 20pt;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 15pt;
  }
`;

const Title = styled.h3`
  margin: 0;
  font-size: 12pt;
  color: ${(props) => props.theme.blue};

  @media (max-width: 450px) {
    font-size: 10pt;
  }
`;

const Description = styled.span`
  font-size: 11pt;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 9pt;
  }
`;

const Content = styled.div`
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1d1d1;
`;

const ProgressContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 7px;
`;

const Progress = styled(AntdProgress)`
  .ant-progress-inner {
    background-color: #e3e3e3;
  }
`;

const PercentInfo = styled.span`
  font-size: 10pt;
`;

const EmojiContainer = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0 0 5px 2px #7e7e7e;
`;

const TrendContainer = styled.div`
  width: 100%;
  margin: auto;
  padding: 5px 0;
  background-color: white;
  color: ${(props) => props.theme.red};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 9pt;
    padding: 2px 0;
  }
`;

const ResultInfoContainer = styled.div`
  width: 100%;
  padding-top: 10px;
`;

const ResultInfo = styled.div`
  text-align: end;
  color: ${(props) => props.theme.red};
  font-size: 10pt;
  font-weight: bold;

  @media (max-width: 450px) {
    font-size: 8pt;
  }
`;

const ResultDate = styled.div`
  text-align: end;
  color: #5e5e5e;
  font-size: 8pt;

  @media (max-width: 450px) {
    font-size: 7pt;
  }
`;
