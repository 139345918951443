import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router';
import { Breadcrumb, TitleDescription, Table, Select, LoadingIndicator, verdade } from '@digi-tim-19/components';
import styled from 'styled-components';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { routes } from '../../../config/routes';
import { useClient } from '../../../autogenerated/client/client';
import {
  EnumIncentiveCampaignStatus,
  SortFindManyIncentiveCampaignInput,
  ValidityEnum,
} from '../../../autogenerated/client/types';

import { CampanhaIncentivoListWrapper, FilterContainer, Button, CleanButton } from './styles';
import { columns } from './columns';
import { message, Input, DatePicker } from 'antd';
import { formatDate } from '@digi-tim-19/utils/build';
import { useChannelFindMany } from '../../../hooks/channels/channelFindMany';
import { useRegionFindMany } from '../../../hooks/regions/useRegionsFindMany';
import moment from 'moment';
import { escapeStringRegexp } from '../../../utils/formatters';

const configPage = {
  pageName: 'Campanhas e resultados',
  icon: 'trophy',
  description: 'Cadastramento de campanhas de incentivo e resultados.',
  messageDeletedSuccess: 'Campanha excluida com sucesso',
  textButtonNew: 'CADASTRAR CAMPANHA',
  routeNew: routes.campanhasIncentivoCadastrar.mount(),
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo' },
    { title: 'Campanhas de Incentivo' },
    { title: 'Listar' },
  ],
};

const { Search: AntdSearch } = Input;

const Search = styled(AntdSearch)`
  width: 100% !important;
  input {
    color: ${(props) => props.theme.blue};
  }
  svg {
    fill: ${(props) => props.theme.blue};
    font-size: 18px;
  }
`;

const { RangePicker } = DatePicker;

export const CampanhaIncentivoListar = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [channel, setChannel] = React.useState('');
  const [region, setRegion] = React.useState('');
  const [filterSearch, setFilterSearch] = useState('');

  const [createdAt, setCreatedAt] = useState();
  const [show, setShow] = useState([]);

  const [updatedAt, setUpdatedAt] = useState();
  const [showUpdatedAt, setShowUpdatedAt] = useState([]);

  const [filterStatus, setFilterStatus] = useState();
  const getIncentiveCampaign = useClient('IncentiveCampaignPagination');
  const IncentiveCampaignRemoveById = useClient('IncentiveCampaignRemoveById');

  const ChannelFindMany = useChannelFindMany({
    filter: {},
    sort: '_ID_ASC' as any,
  });

  const RegionFindMany = useRegionFindMany({
    filter: {},
    sort: '_ID_ASC' as any,
  });

  const loading = RegionFindMany.loading || ChannelFindMany.loading;

  const channels = parseOptions(verdade(ChannelFindMany.result), loading ? 'carregando...' : 'CANAL DA CAMPANHA');

  const allRegions = useMemo(() => RegionFindMany.result, [RegionFindMany.result]);
  const allChannels = useMemo(() => ChannelFindMany.result, [ChannelFindMany.result]);

  const regions = parseOptions(verdade(RegionFindMany.result), loading ? 'carregando...' : 'REGIÃO DA CAMPANHA').filter(
    (el) => el.value !== 'HQ',
  );

  useEffect(() => {
    const regionSelected = allRegions?.find((x) => x?._id === region);
    const channelSelected = allChannels?.find((x) => x?._id === channel);

    let idsRegions: string[] = [];
    let idsRoleGroups: string[] = channelSelected?.roleGroups?.length
      ? channelSelected?.roleGroups.map((item) => item?._id || '')
      : [];

    regionSelected?.items?.map((item) => {
      return item?.items?.map((subItem) => {
        return idsRegions.push(subItem?._id || '');
      });
    });

    getIncentiveCampaign.fetch({
      appendToFragment: appendToFragmentCampaign,
      variables: {
        perPage: 10,
        page: page,
        sort: SortFindManyIncentiveCampaignInput.IdDesc,
        filter: {
          status: filterStatus,
          validityEnum: ValidityEnum.Any,
          //availableAtChannels: !!channel ? [channel] : undefined,
          inAvailableAtRoleGroups: !!idsRoleGroups.length ? idsRoleGroups : undefined,
          inAvailableAtRegions: !!idsRegions.length ? idsRegions : undefined,
          title: !filterSearch ? undefined : `regex(${escapeStringRegexp(filterSearch)})`,
          createdAtRange: createdAt,
          updatedAtRange: updatedAt,
        },
      },
    });
  }, [filterStatus, page, channel, region, allRegions, filterSearch, createdAt, updatedAt]); //eslint-disable-line

  const total = getIncentiveCampaign?.result?.pageInfo.itemCount;

  const data = getIncentiveCampaign.result?.items?.map((campaign: any) => {
    return {
      changedBy: campaign.changedBy,
      key: campaign?._id,
      externalId: campaign?.externalId,
      title: campaign?.title,
      status: campaign?.status?.label,
      cadastrada: formatDate(campaign?.createdAt, 'DD/MM/YYYY'),
      publicada: formatDate(campaign?.validity?.end, 'DD/MM/YYYY'),
      atualizada: formatDate(campaign?.updatedAt, 'DD/MM/YYYY'),
      onEdit: () => history.push(routes.campanhasIncentivoEditar.mount(campaign?._id)),
      onDelete: () => {
        IncentiveCampaignRemoveById.fetch({
          variables: { _id: campaign._id },
          afterMutate: /^IncentiveCampaign/,
        }).then((ctx) => {
          message.success(configPage.messageDeletedSuccess);
        });
      },
      onRead: () => history.push(routes.campanhasIncentivoVisualizar.mount(campaign?._id)),
      onDuplicate: () => {
        history.push(routes.campanhasIncentivoEditar.mount(campaign?._id), { duplicate: true });
      },
      onEditFile: () => {
        history.push(routes.visualizarArquivosCampanha.mount(campaign?._id));
      },
    };
  });

  const onSearch = useCallback(
    (e) => {
      setFilterSearch(e);
    },
    [data],
  );

  const onChange = (e: any) => {
    if (e.length > 0) {
      setCreatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString(),
      });
      setShow(e);
    } else {
      setShow([]);
      setCreatedAt(undefined);
    }
  };

  const onChangeUpdatedAt = (e: any) => {
    if (e.length > 0) {
      setUpdatedAt({
        start: moment(e[0]!).startOf('day')?.toISOString(),
        end: moment(e[1]!).endOf('day')?.toISOString(),
      });
      setShowUpdatedAt(e);
    } else {
      setShowUpdatedAt([]);
      setUpdatedAt(undefined);
    }
  };

  const resetFilters = () => {
    setCreatedAt(undefined);
    setShow([]);
    setUpdatedAt(undefined);
    setShowUpdatedAt([]);
  };

  return (
    <PageTemplate>
      <CampanhaIncentivoListWrapper>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />

        <FilterContainer>
          <Button to={routes.campanhasIncentivoCadastrar.mount()}>{configPage.textButtonNew}</Button>
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filterSearch}
            onChange={(e) => {
              setPage(1);
              onSearch(e.target.value);
            }}
          />
          <Select
            placeholder="status"
            options={statusOptions}
            value={filterStatus}
            onChange={(e) => {
              setPage(1);
              setFilterStatus(e);
            }}
          />

          <Select
            placeholder="Canal da campanha"
            options={channels}
            onChange={(e) => {
              setChannel(e);
            }}
            value={channel}
          />

          <Select
            placeholder="Região da campanha"
            options={regions}
            onChange={(e) => {
              setRegion(e);
            }}
            value={region}
          />
          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['CADASTRO', '']}
            onChange={(e) => {
              setPage(1);
              onChange(e);
            }}
            value={show}
          />

          <RangePicker
            getCalendarContainer={(triggerNode: any) => triggerNode.parentNode}
            format={'DD/MM/YYYY'}
            placeholder={['ATUALIZAÇÃO', '']}
            onChange={(e) => {
              setPage(1);
              onChangeUpdatedAt(e);
            }}
            value={showUpdatedAt}
          />
          <CleanButton onClick={() => resetFilters()}>Limpar filtros</CleanButton>
        </FilterContainer>
        {getIncentiveCampaign.loading ? (
          <LoadingIndicator />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            pagination={{
              pageSize: 10,
              current: page,
              total: total!,
              onChange: (e) => setPage(e),
            }}
          />
        )}
      </CampanhaIncentivoListWrapper>
    </PageTemplate>
  );
};

const statusOptions = [
  { label: 'RESULTADOS', value: EnumIncentiveCampaignStatus.WithResults },
  { label: 'ANDAMENTO', value: EnumIncentiveCampaignStatus.InProgress },
  { label: 'APURAÇÃO', value: EnumIncentiveCampaignStatus.InVerification },
  { label: 'ENCERRADAS', value: EnumIncentiveCampaignStatus.Closed },
  {
    label: 'ENCERRADA/SEM GANHADORES',
    value: EnumIncentiveCampaignStatus.ClosedWithoutWinners,
  },
  {
    label: 'ENCERRADA/PARCEIRO',
    value: EnumIncentiveCampaignStatus.ClosedPartner,
  },
  {
    label: 'DISTRIBUIÇÃO TIMCOINS',
    value: EnumIncentiveCampaignStatus.WaitingPointsDistribution,
  },
  { label: 'TODAS', value: undefined },
];

const appendToFragmentCampaign = `
count
pageInfo {
  itemCount
}
items {
  changedBy {
    name
    dateText
  },
  _id
  externalId
  title
  createdAt
  updatedAt
  cardImageFile {
    signedUrl
  }
  validity {
    start
    end
  }
  status {
    _id
    label
    value
  }
}

`;

function parseOptions(items: any[], placeholder = '') {
  return [{ label: placeholder, value: '' }].concat(
    items.map((el) => ({
      label: el.name || '',
      value: el._id || '',
    })),
  );
}
