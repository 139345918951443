"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var graphql_clientgen_1 = require("graphql-clientgen");

exports.TablePriceActionsContext = React.createContext({});
var Provider = exports.TablePriceActionsContext.Provider;

exports.TablePriceActionsProvider = function (props) {
  var children = props.children,
      store = props.store;
  var fetchByRegion = graphql_clientgen_1.fetchGraphql('TablePriceSearchByRegionsLocationTypeAndNotContainsSomeIds', {}, store);
  return React.createElement(Provider, {
    value: {
      fetchByRegion: fetchByRegion
    }
  }, children);
};

function useTablePriceActions() {
  return React.useContext(exports.TablePriceActionsContext);
}

exports.useTablePriceActions = useTablePriceActions;