import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Button, message } from 'antd';
import { useClient } from '../../autogenerated/client/client';

const ModalIncentiveCampaignPointsWrapper = styled.div``;
const ContentWrapper = styled.div``;

export const ModalIncentiveCampaignPoints = () => {
  const userCreditOperation = useClient('UserCreditOperationPagination');
  const setWarningAt = useClient('SetWarningAt');

  const [incetiveCampaigns, setIncentiveCampaigns] = useState<any[]>();

  const handleClose = (itemSelected: any) => {
    setWarningAt
      .fetch({
        variables: {
          idUserCreditOperation: itemSelected._id,
        },
      })
      .then((ctx) => {
        closeItemModal(itemSelected);
      });
  };

  function closeItemModal(itemSelected: any) {
    const newIncetiveCampaigns = incetiveCampaigns?.map((item) => {
      if (item._id === itemSelected._id) {
        return {
          ...item,
          isOpen: false,
        };
      }

      return item;
    });

    setIncentiveCampaigns(newIncetiveCampaigns);
  }

  useEffect(() => {
    userCreditOperation
      .fetch({
        variables: {
          perPage: 50,
          page: 1,
        },
        appendToFragment: userCreditOperationAppendFragment,
      })
      .then((ctx) => {
        if (ctx.result && ctx.result.items && ctx.result.items.length > 0) {
          const warningPendigs = ctx.result.items
            .filter((x) => x?.creditOriginCampaignData?.warningAt === null)
            .map((item) => {
              return {
                ...item,
                isOpen: true,
              };
            });
          setIncentiveCampaigns(warningPendigs);
        }
      });
  }, []);

  return (
    <ModalIncentiveCampaignPointsWrapper>
      {incetiveCampaigns?.map((item, index) => {
        const text = `Você recebeu ${item.creditOriginCampaignData.points} TIMCOINS na campanha ${item.creditOriginCampaignData.campaignTitle}`;
        return (
          <Modal
            key={index}
            title="TIMCOINS de campanha de incentivo"
            visible={item.isOpen}
            onCancel={() => handleClose(item)}
            footer={[
              <Button key="submit" type="primary" onClick={() => handleClose(item)}>
                Ok
              </Button>,
            ]}
          >
            <ContentWrapper>
              <p>{text}</p>
            </ContentWrapper>
          </Modal>
        );
      })}
    </ModalIncentiveCampaignPointsWrapper>
  );
};

const userCreditOperationAppendFragment = `
items {
  _id
  userId
  op
  value
  creditOriginCampaignData {
    campaignTitle
    points
    internalCampaignId
    addedAt
    warningAt
  }
}`;
