"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var build_1 = require("@digi-tim-19/utils/build"); //import { message } from 'antd';


var DataProviders_1 = require("../DataProviders/DataProviders"); // const msgBlackList =
//   'No seu texto foi encontrada uma palavra proibida. Reavalie seu comentário e tente novamente. O seu comentário não será exibido para os outros usuários.';


function useComments() {
  var textFindBlackList = DataProviders_1.useClient('TextFindBlackList');
  var sendMessage = DataProviders_1.useClient('CommentCreateOne');

  function validateWordInBlackList(word) {
    var resultTest = build_1.validateWord(word); //console.log(resultTest);

    if (!resultTest) {
      //message.warning(msgBlackList);
      return true;
    }

    return true; //precisei fazer esse ajuste técnico aqui pra ir ao ar hj, 23/03/22, arrumar isso amanhã
  }

  function onSubmitComment(record, cb) {
    var textToInsert = record.textToInsert,
        parentId = record.parentId,
        replyRecipientId = record.replyRecipientId,
        roomId = record.roomId; // textFindBlackList
    //   .fetch({
    //     variables: {
    //       text: textToInsert
    //     }
    //   })
    //   .then((ctx) => {
    //     if (!ctx.result?.isOk) {
    //       message.warning(msgBlackList);
    //     } else {
    //       sendMessage
    //         .fetch({
    //           variables: {
    //             record: {
    //               parentId: parentId,
    //               content: textToInsert,
    //               replyRecipientId,
    //               roomId
    //             }
    //           }
    //         })
    //         .then((ctx) => {
    //           cb(ctx);
    //         });
    //     }
    //   });

    sendMessage.fetch({
      variables: {
        record: {
          parentId: parentId,
          content: textToInsert,
          replyRecipientId: replyRecipientId,
          roomId: roomId
        }
      }
    }).then(function (ctx) {
      cb(ctx);
    });
  }

  return {
    sendMessageLoading: sendMessage.loading,
    textFindBlackListLoading: textFindBlackList.loading,
    validateWordInBlackList: validateWordInBlackList,
    onSubmitComment: onSubmitComment
  };
}

exports.useComments = useComments;