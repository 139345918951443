import React from 'react';

export const useInMemoPagination = <T extends unknown>({
  data,
  perPage,
  filterArgs = {}
}: {
  data: T[];
  perPage: number;
  filterArgs?: Record<string, string>;
}) => {
  const [page, setPage] = React.useState<number>(1);

  const filteredData = React.useMemo<T[] | undefined>(() => {
    const args = Object.entries(filterArgs);
    if (!args.length) return;

    return data?.filter((entry) =>
      args.every(([key, value]) => {
        if (value === '') return true;
        const entryvalue = (entry as Record<string, string>)[key];
        return entryvalue.toLowerCase().includes(value.toLowerCase());
      })
    );
  }, [data, filterArgs]);

  const dataToRender = React.useMemo<T[]>(() => {
    const init = (page - 1) * perPage;
    const end = init + perPage;
    return filteredData ? filteredData.slice(init, end) : data?.slice(init, end);
  }, [data, page, perPage, filteredData]);

  React.useEffect(() => {
    const noRender = dataToRender.length === 0;
    if (noRender && filteredData?.length) {
      setPage(Math.ceil(filteredData?.length / perPage));
    }
  }, [filteredData]);

  return {
    dataToRender,
    pagination: {
      current: page,
      pageSize: perPage,
      total: filteredData ? filteredData.length : data?.length,
      onChange: setPage
    }
  };
};
