import React, { useEffect, useMemo, useState } from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import { Breadcrumb, Button, Table, TitleDescription, useClient } from '@digi-tim-19/components';
import { ApuracaoCampanhaListWrapper, ContainerButtons } from './styles';
import { routes } from '../../../config/routes';
import { useHistory, useParams } from 'react-router';
import { useIncentiveCampaign } from '../../../hooks/incentiveCampaign/useIncentiveCampaign';
import { EnumIncentiveCampaignStatus, IncentiveCampaign } from '../../../autogenerated/client/types';
import { columns } from './columns';
import { RenderComponentAsync } from '../../../components/Layout/RenderComponentAsync';
import { useInvoiceOriginOptions } from '../useInvoiceOriginOptions';
import { TreeSelect } from 'antd';
import { downloadXLSX } from '../../../utils/downloadXlsx';
import { useWalletOptions } from '../useWalletsOptions';
import { Alert, message } from 'antd';
import { consumerRoutes } from '@digi-tim-19/utils';
import { formatNumberToPtBr } from '../../../utils/formatters';

const configPage = {
  pageName: 'Apuração Campanha de incentivo',
  icon: 'trophy',
  description: 'Apuração de TIMCOINS para campanhas de incentivo e resultados.',
  breadcrumb: [
    { title: 'Home', link: routes.home.mount() },
    { title: 'Gerenciar conteúdo' },
    {
      title: 'Campanhas de Incentivo',
      link: routes.campanhasIncentivoListar.mount(),
    },
    { title: 'Apuração' },
    { title: 'Listar' },
  ],
};

export const ApuracaoCampanha = () => {
  const { idCampaign } = useParams<{ idCampaign: string }>();
  const invoiceOrigins = useInvoiceOriginOptions();
  const [page, setPage] = useState(1);
  const incentiveCampaign = useIncentiveCampaign(idCampaign, appendToFragment);
  const IncentiveCampaignPayPoints = useClient('IncentiveCampaignPayPointsNew');
  const campaign: IncentiveCampaign = incentiveCampaign.result || ({} as IncentiveCampaign);
  const [invoice, setInvoice] = useState();
  const [wallet, setWallet] = useState('');
  const walletOptions = useWalletOptions(invoice || campaign?.invoiceOrigin || '');
  const [errors, setErrors] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setWallet(walletOptions?.[0]?.value || '');
  }, [JSON.stringify(walletOptions)]);

  useEffect(() => {
    if (IncentiveCampaignPayPoints.error) {
      const errorsBreak: any = IncentiveCampaignPayPoints.error.split('\\n');

      if (errorsBreak) {
        setErrors(
          errorsBreak.map((item: any, index: number) => {
            return <p key={`${index}-error`}>{item}</p>;
          }),
        );
      }
    }
  }, [IncentiveCampaignPayPoints.error]);

  const downloadReport = () => {
    const schema = {
      name: { label: 'Nome' },
      matricula: { label: 'Matricula' },
      cpf: { label: 'CPF' },
      channel: { label: 'Canal' },
      region: { label: 'Regional' },
      points: { label: 'TIMCOINS' },
    };

    const dataSerialized = campaign.campaignDistribution.map((c) => ({
      name: c?.user.name,
      matricula: c?.user.matricula,
      cpf: c?.user.CPF,
      channel: c?.user.channels?.[0]?.replace('_', ' ').toUpperCase(),
      region: c?.user.region,
      points: c?.points,
    }));

    downloadXLSX('resultado_incentivo', schema, dataSerialized);
  };

  const totalPoints = useMemo(
    () =>
      campaign.campaignDistribution?.filter((el) => el?.points)?.reduce((acc, curr) => acc + Number(curr?.points), 0),
    [JSON.stringify(campaign.campaignDistribution)],
  );

  const getLabelValue = (value: string) => {
    const regex = /R\$\s*([\d\.,]+)/;
    const matches = value.match(regex);
    if (!matches) return 0;
    return parseFloat(matches[1].replace(/\./g, '').replace(',', '.'));
  };

  const handlePayment = () => {
    const walletSelected = walletOptions.find((el) => el.value === wallet);
    const walletValue = getLabelValue(walletSelected?.label!);

    if (walletValue < totalPoints) {
      return message.error('Não existe saldo suficiente na carteira selecionada');
    }

    IncentiveCampaignPayPoints.fetch({
      variables: {
        campaignId: campaign._id,
        walletId: wallet,
      },
    }).then((ctx) => {
      if (ctx.errors) {
        message.error(ctx.errors.join('\n'));
      } else {
        message.success('TIMCOINS distribuídos');
        history.push(consumerRoutes.campanhasIncentivoListar.mount());
      }
    });
  };

  return (
    <PageTemplate>
      <ApuracaoCampanhaListWrapper>
        <Breadcrumb items={configPage.breadcrumb} />
        <TitleDescription iconType={configPage.icon} title={configPage.pageName} description={configPage.description} />
        <p style={{ marginLeft: 5, fontWeight: 700 }}>{`Total de pontos: ${formatNumberToPtBr(totalPoints || 0)}`}</p>
        <ContainerButtons>
          <Button onClick={() => downloadReport()} disabled={!campaign.campaignDistribution?.length}>
            Exportar Relatório
          </Button>

          <div style={{ marginLeft: 'auto' }}>
            <TreeSelect
              style={{ width: '250px', marginRight: '15px' }}
              dropdownStyle={{ maxHeight: '40vh' }}
              treeData={invoiceOrigins}
              placeholder="Origem Fatura"
              onChange={(e: any) => {
                setInvoice(e);
              }}
              value={invoice || campaign?.invoiceOrigin}
            />
            <TreeSelect
              style={{ width: '250px', marginRight: '15px' }}
              dropdownStyle={{ maxHeight: '40vh' }}
              treeData={walletOptions}
              placeholder="Carteira"
              onChange={(e: any) => {
                setWallet(e);
              }}
              value={wallet}
            />
            <Button
              disabled={
                IncentiveCampaignPayPoints.loading ||
                campaign?.status?._id === EnumIncentiveCampaignStatus.Closed ||
                !wallet
              }
              onClick={() => handlePayment()}
            >
              Distribuir TIMCOINS
            </Button>
          </div>
        </ContainerButtons>
        <RenderComponentAsync loading={incentiveCampaign.loading}>
          {!!IncentiveCampaignPayPoints.error && (
            <Alert message="Erro" description={<div>{errors.map((item) => item)}</div>} type="error" showIcon />
          )}
          <Table
            columns={columns}
            dataSource={campaign.campaignDistribution}
            pagination={{
              pageSize: 10,
              current: page,
              total: campaign.campaignDistribution?.length,
              onChange: (e) => setPage(e),
            }}
          />
        </RenderComponentAsync>
      </ApuracaoCampanhaListWrapper>
    </PageTemplate>
  );
};

const appendToFragment = `
    status { _id }
    invoiceOrigin
    campaignDistribution {
      user {
        name
        matricula
        CPF
        channels
        region
      }
      points
    }
`;
