"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function isHTML(str) {
  return /<\/?[a-z][\s\S]*>/i.test(str);
}

exports.isHTML = isHTML;