import React, { useState } from 'react';
import { Icon } from 'antd';
import {
  ContentDetailRanking,
  DetailParticipant,
  DetailParticipantLeft,
  DetailParticipantPositionLeft,
  DetailParticipantPositionRight,
  DetailParticipantRight,
  DividerPosition,
  EmojiContainerRanking,
  IconContainerRanking,
  InfoContainerRanking,
  PercentInfoRanking,
  ProgressContainerRanking,
  ProgressRanking,
} from '../stylesCampanhaRanking';
import { ButtonShowRanking } from './ButtonShowRanking';
import { IncentiveCampaignData } from '../../../autogenerated/client/types';
import { RankingIncentivo } from '../RankingIncentivo/RankingIncentivo';
import { LoadingIndicator } from '@digi-tim-19/components';

interface Props {
  detail: IncentiveCampaignData;
  rankingData: any[];
  loadingRanking: boolean;
}

export const RankingIncentive = ({ detail, rankingData, loadingRanking }: Props) => {
  const [showRanking, setShowRanking] = useState<boolean>(false);

  return (
    <>
      <DetailParticipant>
        <DetailParticipantLeft>
          <DetailParticipantPositionLeft>
            <span>Minha posição</span>
            <div>
              <span>{detail?.position ? `${detail.position}°` : '-'}</span>
            </div>
          </DetailParticipantPositionLeft>
          <DividerPosition />
          <DetailParticipantPositionRight>
            <div>
              <span>Pontuação do Ranking</span>
              <span>{Number(detail?.results?.[0]?.result || 0).toFixed(2)}</span>
            </div>
          </DetailParticipantPositionRight>
        </DetailParticipantLeft>
        <DetailParticipantRight isNumber={!isNaN(Number(detail?.points))}>
          <span>TIMCOINS Catálogo</span>
          <span>{detail?.points || 0}</span>
          <span>TIMCOINS</span>
        </DetailParticipantRight>
      </DetailParticipant>

      <InfoContainerRanking>
        <h3>DETALHAMENTO</h3>
        {detail?.results?.map((detail) => (
          <ContentDetailRanking>
            <IconContainerRanking>
              <Icon type="user" style={{ fontSize: '24px' }} />
            </IconContainerRanking>
            <ProgressContainerRanking>
              <ProgressRanking percent={Number(((detail.result / detail.goal) * 100).toFixed(2))} showInfo={true} />
              <PercentInfoRanking>
                {`${detail.description}: `}
                <strong>{` ${Number(detail.result).toFixed(2)} / ${Number(detail.goal).toFixed(2)}`}</strong>
              </PercentInfoRanking>
            </ProgressContainerRanking>
            <EmojiContainerRanking>
              <Icon type="smile" />
            </EmojiContainerRanking>
          </ContentDetailRanking>
        ))}
      </InfoContainerRanking>

      {detail?.elegibility?.length ? (
        <InfoContainerRanking>
          <h3>CRITÉRIO DE ELEGIBILIDADE</h3>
          {detail?.elegibility?.map((detail) => (
            <ContentDetailRanking>
              <IconContainerRanking>
                <Icon type="user" style={{ fontSize: '24px' }} />
              </IconContainerRanking>
              <ProgressContainerRanking>
                <ProgressRanking percent={(detail.result / detail.goal) * 100} showInfo={true} />
                <PercentInfoRanking>
                  {`${detail.description}: `}
                  <strong>{` ${detail.result} / ${detail.goal}`}</strong>
                </PercentInfoRanking>
              </ProgressContainerRanking>
            </ContentDetailRanking>
          ))}
        </InfoContainerRanking>
      ) : null}

      <ButtonShowRanking show={showRanking} setShow={setShowRanking} />

      {loadingRanking && <LoadingIndicator />}

      {detail?.position && !loadingRanking && showRanking && <RankingIncentivo data={rankingData} myData={detail} />}
    </>
  );
};
