import React, { useEffect, useMemo } from 'react';
import { PDF, ShareModalButton } from '@digi-tim-19/components';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useClient } from '../../autogenerated/client/client';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { Icon } from 'antd';
import { colors } from '@digi-tim-19/theme';
import { useDimensions } from '@digi-tim-19/utils';

const P = styled.p`
  margin-left: 10px;
  font-size: 30px;
  line-height: 37px;
  font-family: TimLight, fantasy;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #00508c;
`;

export const PageLoadPDF = () => {
  const { id } = useParams<{ id: string }>();
  const { isMobile } = useDimensions();

  const apiAnalyticsDownload = useClient('AnalyticsDownloads');

  const getMaterials = useClient('MaterialFindOne', {
    appendToFragment: `
      title
      files {
        _id
        kind
        signedUrl
        downloadUrl
        extension
      }
      categories {
        _id
      }
    `,
  });

  useEffect(() => {
    getMaterials.fetch({
      variables: {
        filter: {
          _id: id,
        },
      },
    });
  }, [id]);

  const viewer = 'https://pdf.tanaredetim.com.br/web/?file=';
  const viewerParams =
    '&beforePrint=true&afterPrint=true&pagesLoaded=true&pageChange=true&openFile=false&download=true&viewBookmark=false&print=true&fullScreen=true&find=true&errorMessage=ops...&errorAppend=true';

  const isOfferMap = useMemo(
    () => getMaterials.result?.categories.some((category) => category?._id?.includes('mapa_de_ofertas')),
    [getMaterials.result],
  );

  const handleTrackDownload = (fileId: string | undefined) => apiAnalyticsDownload.fetch({ variables: { fileId } });

  return (
    <PageTemplate>
      <div>
        <P>{getMaterials.result?.title}</P>

        {getMaterials.result?.files?.map((item, index) => {
          const url = `${viewer}${encodeURIComponent(item?.signedUrl || '')}${viewerParams}`;

          return (
            <div key={index}>
              {item?.extension?.toLowerCase() === 'pdf' && !isMobile && (
                <div style={{ height: '1200px', overflow: 'hidden' }}>
                  <iframe
                    style={{ width: '100%', height: '100%' }}
                    id={item?.signedUrl ?? ''}
                    src={item?.signedUrl ?? ''}
                  />
                </div>
              )}

              {item?.extension?.toLowerCase() === 'pdf' && isMobile && (
                <PDF key={item?._id} src={url} srcIframe={item?.signedUrl || ''} />
              )}

              <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                {item?.signedUrl && !isOfferMap ? (
                  <a
                    href={item.signedUrl as string}
                    className="download"
                    download
                    onClick={() => handleTrackDownload(item?._id)}
                  >
                    <Icon type="download" style={{ color: colors.gray }} />
                    Download
                  </a>
                ) : null}

                {getMaterials?.result && (
                  <ShareModalButton
                    material={{
                      _id: getMaterials?.result._id,
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </PageTemplate>
  );
};
