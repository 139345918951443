import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Button, Icon, Modal } from 'antd';
import { Breadcrumb, LoadingIndicator, PDF } from '@digi-tim-19/components';
import { IncentiveCampaign, IncentiveCampaignData } from '../../autogenerated/client/types';
import { PageTemplate } from '../../components/Layout/PageTemplate';
import { useIncentiveCampaign } from '../../hooks/incentiveCampaign/useIncentiveCampaign';
import { routes } from '../../config/routes';
import moment from 'moment';
import 'moment/locale/pt-br';
import { PageTitle } from './Components/PageTitle';
import { ButtonPeriod, ButtonRegulation, ButtonsCampaignContainer2 } from './stylesCampanhaRanking';
import { CampanhaVigenteParticipant } from './CampanhaVigenteParticipant';
import { CampanhaVigenteManager } from './CampanhaVigenteManager';
import { CampanhaVigenteViewMode } from './CampanhaVigenteViewMode';
import { CampanhaVigenteExterna } from './CampanhaVigenteExterna';

moment.locale('pt-BR');

const campaignPrizeMap: Record<string, string> = {
  timcoins: 'TIMCOINS',
  voucher_multi_cash: 'Voucher Multi Cash',
  voucher_ifood: 'Voucher Ifood',
  viagem: 'Viagem',
};

export interface UserCampaignDetais {
  name: string;
  position: string;
  result: number;
  points: string;
  userBooster: number;
  details: Detail[];
  rankingData: any[];
  elegibility: Elegibility[];
  results: any[];
}

export interface Detail {
  kpiId: string;
  weight: number;
  criteria: string;
  description: string;
  result: number;
  tend: number;
  userBooster: number;
}

export interface Elegibility {
  kpiId: string;
  description: string;
  eligibility: boolean;
  goal: number;
  points: number;
  result: number;
  tend: number;
  userBooster: number;
}

export const CampanhaIncentivoVigente = () => {
  const { params } = useRouteMatch<{ id: string }>();
  const incentiveCampaign = useIncentiveCampaign(params?.id, appendToFragment);
  const [detailData, setDetailData] = useState<IncentiveCampaignData[]>();
  const [showModalRegulation, setShowModalRegulation] = useState(false);
  const campaign: IncentiveCampaign = incentiveCampaign.result || ({} as IncentiveCampaign);
  const isParticipant = campaign.viewMode === 'participant';
  const isManager = campaign.viewMode === 'manager';

  const viewer = 'https://pdf.tanaredetim.com.br/web/?file=';
  const viewerParams =
    '&beforePrint=true&afterPrint=true&pagesLoaded=true&pageChange=true&openFile=false&download=true&viewBookmark=false&print=true&fullScreen=true&find=true&errorMessage=ops...&errorAppend=true';
  const url = `${viewer}${encodeURIComponent(campaign?.regulationFile?.signedUrl || '')}${viewerParams}`;

  const history = useHistory();

  useEffect(() => {
    if (campaign.results) return history.push(routes.campanhasIncentivoVigenteOld.mount(params?.id));
  }, [incentiveCampaign]);

  const configPage = {
    pageName: 'Campanhas de Incentivo',
    icon: 'trophy',
    description: '',
    breadcrumb: [
      { title: 'Home', link: routes.home.mount() },
      {
        title: 'Campanhas de Incentivo',
        link: routes.campanhasIncentivoVigentes.mount(),
      },
      { title: 'Campanhas Vigentes' },
    ],
  };

  const HandleComponent = () => {
    if (campaign.externalData) return <CampanhaVigenteExterna campaign={campaign} />;

    if (detailData)
      return <CampanhaVigenteViewMode detailData={detailData} campaign={campaign} setDetailData={setDetailData} />;

    if (!detailData && isManager) return <CampanhaVigenteManager campaign={campaign} setDetailData={setDetailData} />;

    if (!detailData && isParticipant) return <CampanhaVigenteParticipant campaign={campaign} />;

    return null;
  };

  return (
    <PageTemplate>
      <>
        <Breadcrumb items={configPage.breadcrumb} />
        <PageTitle icon={<Icon type="trophy" />} title="CAMPANHAS DE INCENTIVO" />
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div style={{ width: '750px' }}>
            {incentiveCampaign.loading && <LoadingIndicator />}
            <HandleComponent />
            {!campaign.externalData && (
              <>
                <ButtonsCampaignContainer2>
                  <ButtonRegulation onClick={() => setShowModalRegulation(true)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                      style={{ width: '35px' }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                      />
                    </svg>

                    <span>REGULAMENTO</span>
                  </ButtonRegulation>
                  <ButtonPeriod>
                    <div>
                      <span>PERÍODO:</span>
                    </div>
                    <div>
                      <span>{`DE: ${moment(campaign.validity?.start).format('DD/MM/YYYY')}`}</span>
                      <span>{`ATÉ: ${moment(campaign.validity?.end).format('DD/MM/YYYY')}`}</span>
                    </div>
                  </ButtonPeriod>
                </ButtonsCampaignContainer2>
                {campaign.prizeType && (
                  <ButtonsCampaignContainer2>
                    <ButtonPeriod>
                      <span style={{ fontSize: '20px' }}>PRÊMIO:</span>
                      <span style={{ fontSize: '18px' }}>{campaignPrizeMap[`${campaign.prizeType}`]}</span>
                    </ButtonPeriod>
                  </ButtonsCampaignContainer2>
                )}
              </>
            )}
            {/* {isManager && <Button onClick={handleDownloadReport}>DOWNLOAD DE RELATÓRIO</Button>} */}
          </div>
        </div>

        <Modal
          title="Regulamento"
          style={{ top: 20 }}
          bodyStyle={{ height: '75vh', padding: 0 }}
          visible={showModalRegulation}
          onOk={() => setShowModalRegulation(false)}
          onCancel={() => setShowModalRegulation(false)}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '12px' }}>
              <Button
                onClick={() => {
                  let a = document.createElement('a');
                  a.href = campaign.externalData?.regulationFile?.signedUrl ?? '';
                  a.download = campaign.externalData?.regulationFile?.signedUrl ?? '';
                  a.click();
                }}
              >
                Download
              </Button>
              <Button onClick={() => setShowModalRegulation(false)}>Ok</Button>
            </div>
          }
        >
          <iframe
            style={{ width: '100%', height: '100%' }}
            id={campaign.regulationFile?.signedUrl ?? ''}
            src={campaign.regulationFile?.signedUrl ?? ''}
          />
        </Modal>
      </>
    </PageTemplate>
  );
};

const appendToFragment = `
  title
  validity {
    start
    end
  }
  faq
  periodOption,
  kpis
  campaignType
  status {
    _id
    value
  }
  views {
    count
  }
  likes {
    count
  }
  cardImageFile {
    downloadUrl
  }
  bannerContentFile {
    downloadUrl
    signedUrl
    imageInfo {
      height
      width
    }
  }
  regulationFile {
    downloadUrl
    signedUrl

  }
  presentationFile {
    downloadUrl
    signedUrl
  }
  availableAtRoleGroups
  participants {
    _id
    roleGroup {
      _id
    }
    region
    name
  }
  externalData {
    _id
    pendingAccept
    hasCNPJ
    redirectURL
    regulationFile {
      signedUrl
    }
  }
  resultData {
    userId,
    reference,
    incentiveCampaignId,
    incentiveCampaignTypeId,
    kpis,
    points,
    position,
    result,
    elegibility,
    results,
    userDetail,
  }
  prizeType
`;
