"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var graphql_clientgen_1 = require("graphql-clientgen");

exports.PlaceActionsContext = React.createContext({});
var Provider = exports.PlaceActionsContext.Provider;

exports.PlaceActionsProvider = function (props) {
  var children = props.children,
      store = props.store;
  var fetchByRegionAndStatus = graphql_clientgen_1.fetchGraphql('PlaceSearchByChannelSapCodeOrPlaceAndRegion', {}, store);
  return React.createElement(Provider, {
    value: {
      fetchByRegionAndStatus: fetchByRegionAndStatus
    }
  }, children);
};

function usePlaceActions() {
  return React.useContext(exports.PlaceActionsContext);
}

exports.usePlaceActions = usePlaceActions;