"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Recebe uma data no formato DD/MM/YYYY e retorna um objeto Date correspondente
 * @param dateString
 */

exports.getBRDate = function () {
  var dateString = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '31/00/0000';
  // const [d, m, y] = dateString
  //   .replace(/[^\d\/]/gim, '')
  //   .split('/')
  //   .map(Number);
  var digits = dateString.replace(/[^\d]/gim, '').split('');
  var d = +(digits[0] + digits[1]);
  var m = +(digits[2] + digits[3]);
  var y = +(digits[4] + digits[5] + digits[6] + digits[7]);

  if (!d || !m || !y) {
    return null;
  }

  return new Date(y, m - 1, d);
};