// *************
//  * IMPORTS *
// *************
import { GraphQLClient, GraphQLProvider } from 'graphql-clientgen';
import { createBrowserHistory } from 'history';
import React from 'react';
import { Route, Router, Switch } from 'react-router';

import { SocketIOProvider, UserSession } from '@digi-tim-19/utils';

import {
  DataProviders,
  NotFound,
  ThemeProvider,
  graphqlLogsMiddleware,
  graphqlMiddleware,
  routerPageWrapper,
  setAnalyticsConfig,
  useAnalytics,
} from '@digi-tim-19/components';

import { AppMethods, AppMethodsInfo, useClient } from './autogenerated/client/client';
import { API_DOMAIN_URL, API_URL } from './config/apiURL';
import { routes } from './config/routes';
import { AppReduxProvider } from './redux/ReduxProvider';
import { siteTheme } from './styles/theme';

// ***********
//  * PAGES *
// ***********
import { Home } from './pages/Home/Home';
import { Login } from './pages/Login/Login';

import { TabelaPrecoCadastrar } from './pages/TabelaPrecos/Cadastrar/Cadastrar';
import { TabelaPrecoEditar } from './pages/TabelaPrecos/Editar/Editar';
import { TabelaPrecoListar } from './pages/TabelaPrecos/Listar/Listar';
import { TabelaPrecos } from './pages/TabelaPrecos/TabelaPrecos';

import { VejaMaisCanalCadastrar } from './pages/VejaMaisCanal/Cadastrar/Cadastrar';
import { VejaMaisCanalEditar } from './pages/VejaMaisCanal/Editar/Editar';
import { VejaMaisCanalListar } from './pages/VejaMaisCanal/Listar/Listar';
import { VejaMaisCanal } from './pages/VejaMaisCanal/VejaMaisCanal';

import { Informativos } from './pages/Informativos/Informativos';

import { OnTubeCadastrar } from './pages/OnTube/Cadastrar/Cadastrar';
import { OnTubeEditar } from './pages/OnTube/Editar/Editar';
import { OnTubeListar } from './pages/OnTube/Listar/Listar';
import { OnTube } from './pages/OnTube/OnTube';
import { OnTubePlayer } from './pages/OnTube/OnTubePlayer';
import { OnTubePlaylist } from './pages/OnTube/OnTubePlaylist';

import { ConteudoHTML } from './pages/ConteudoMaterial/ConteudoHTML';
import { ProcessosCadastrar } from './pages/Processos/Cadastrar/Cadastrar';
import { ProcessosEditar } from './pages/Processos/Editar/Editar';
import { ProcessosGerenciar } from './pages/Processos/Listar/Listar';
import { Processos } from './pages/Processos/Processos';

import { MapaDeOfertasCadastrar } from './pages/MapaDeOfertas/Cadastrar/Cadastrar';
import { MapaDeOfertasEditar } from './pages/MapaDeOfertas/Editar/Editar';
import { MapaDeOfertasListar } from './pages/MapaDeOfertas/Listar/Listar';
import { MapaDeOfertas } from './pages/MapaDeOfertas/MapaDeOfertas';

import { TaNaMaoCadastrar } from './pages/TaNaMao/Cadastrar/Cadastrar';
import { TaNaMaoEditar } from './pages/TaNaMao/Editar/Editar';
import { TaNaMaoListar } from './pages/TaNaMao/Listar/Listar';
import { TaNaMao } from './pages/TaNaMao/TaNaMao';

import { RegionalCadastrar } from './pages/VejaMaisRegional/Cadastrar/Cadastrar';
import { RegionalEditar } from './pages/VejaMaisRegional/Editar/Editar';
import { RegionalListar } from './pages/VejaMaisRegional/Listar/Listar';
import { Regional } from './pages/VejaMaisRegional/Regional';

import { SistemaVendasCadastrar } from './pages/SistemaVendas/Cadastrar/Cadastrar';
import { SistemaVendasEditar } from './pages/SistemaVendas/Editar/Editar';
import { SistemaVendasListar } from './pages/SistemaVendas/Listar/Listar';
import { SistemasVendas2020 } from './pages/SistemaVendas/SistemaVendas';

import { MapaPDVCadastrar } from './pages/MapaPDV/Cadastrar/Cadastrar';
import { MapaPDVEditar } from './pages/MapaPDV/Editar/Editar';
import { MapaPDVListar } from './pages/MapaPDV/Listar/Listar';
import { MapaPDVCategorias } from './pages/MapaPDV/MapaPDVCategorias';
import { MateriaisPDV } from './pages/MateriaisPDV/MateriaisPDV';

import { ImageViewer } from './pages/ImageViewer/ImageViewer';
import { PageLoadPDF } from './pages/PageLoadPDF/PageLoadPDF';
import { RegulamentoCadastrar } from './pages/Regulamentos/Cadastrar/Cadastrar';
import { RegulamentoEditar } from './pages/Regulamentos/Editar/Editar';
import { RegulamentoListar } from './pages/Regulamentos/Listar/Listar';
import { Regulamentos } from './pages/Regulamentos/Regulamentos';
import { SelecaoCategoria } from './pages/SelecaoCategoria/SelecaoCategoria';

import { LiveCadastrar } from './pages/Live/Cadastrar/Cadastrar';
import { LiveEditar } from './pages/Live/Editar/Editar';
import { LiveListar } from './pages/Live/Listar/Listar';

import { InformativosCadastrar } from './pages/Informativos/Cadastrar/Cadastrar';
import { InformativosDuplicar } from './pages/Informativos/Duplicar/Duplicar';
import { InformativosEditar } from './pages/Informativos/Editar/Editar';
import { InformativosListar } from './pages/Informativos/Listar/Listar';

import { ComparadorAparelhosCadastrar } from './pages/ComparadorAparelhos/Cadastrar/Cadastrar';
import { ComparadorAparelhosEditar } from './pages/ComparadorAparelhos/Editar/Editar';
import { ComparadorAparelhosListar } from './pages/ComparadorAparelhos/Listar/Listar';

import { ArgumentacaoFDV } from './pages/ArgumentacaoFDV/ArgumentacaoFDV';
import { ArgumentacaoFDVCadastrar } from './pages/ArgumentacaoFDV/Cadastrar/Cadastrar';
import { ArgumentacaoFDVEditar } from './pages/ArgumentacaoFDV/Editar/Editar';
import { ArgumentacaoFDVListar } from './pages/ArgumentacaoFDV/Listar/Listar';

import { MapaPecasCadastrar } from './pages/MapaPecas/Cadastrar/Cadastrar';
import { MapaPecasEditar } from './pages/MapaPecas/Editar/Editar';
import { MapaPecasListar } from './pages/MapaPecas/Listar/Listar';
import { MapaPecas } from './pages/MapaPecas/MapaPecas';

import { MateriaisApoioCadastrar } from './pages/MateriaisApoio/Cadastrar/Cadastrar';
import { MateriaisApoioEditar } from './pages/MateriaisApoio/Editar/Editar';
import { MateriaisApoioListar } from './pages/MateriaisApoio/Listar/Listar';
import { MateriaisApoio } from './pages/MateriaisApoio/MateriaisApoio';

import { MateriaisTimEmpresasCadastrar } from './pages/MateriaisTimEmpresas/Cadastrar/Cadastrar';
import { MateriaisTimEmpresasEditar } from './pages/MateriaisTimEmpresas/Editar/Editar';
import { MateriaisTimEmpresasListar } from './pages/MateriaisTimEmpresas/Listar/Listar';
import { MateriaisTimEmpresas } from './pages/MateriaisTimEmpresas/MateriaisTimEmpresas';

import { BannerCadastrar } from './pages/Home/Banners/Cadastrar/Cadastrar';
import { BannerEditar } from './pages/Home/Banners/Editar/Editar';
import { BannerListar } from './pages/Home/Banners/Listar/Listar';

import { Busca } from './pages/Busca/Busca';
import { PageLoadHTML } from './pages/PageLoadHTML/PageLoadHTML';

import { Relatorios } from './pages/Relatorios/Relatorios';

import { AtividadesRecentes } from './pages/AtividadesRecentes/AtividadesRecentes';
import { CadastrarNotificacao } from './pages/Notificacoes/Cadastrar/Cadastrar';
import { EditarNotificacao } from './pages/Notificacoes/Editar/Editar';
import { ListarNotificacoes } from './pages/Notificacoes/Listar/Listar';
import { Notificacoes } from './pages/Notificacoes/Notificacoes';

import { ContestacaoEditar } from './pages/Contestacao/Editar/Editar';
import { ContestacaoListar } from './pages/Contestacao/Listar/Listar';
import { Favoritos } from './pages/Favoritos/Favoritos';
import { PastaFavoritos } from './pages/Favoritos/PastaFavoritos';

import { AcompanharContestacao } from './pages/Contestacao/AcompanharContestacao/AcompanharContestacao';

import { CampanhaIncentivoCadastrar } from './pages/CampanhaIncentivo/Cadastrar/Cadastrar';
import { CampanhaIncentivo } from './pages/CampanhaIncentivo/CampanhaIncentivo';
import { CampanhaIncentivoVigente } from './pages/CampanhaIncentivo/CampanhaVigente';
import { CampanhaIncentivoVigenteOld } from './pages/CampanhaIncentivo/CampanhaVigenteOld';
import { DistribuicaoPontos } from './pages/CampanhaIncentivo/DistribuicaoPontos/DistribuicaoPontos';
import { CampanhaIncentivoEditar } from './pages/CampanhaIncentivo/Editar/Editar';
import { CampanhaIncentivoListar } from './pages/CampanhaIncentivo/Listar/Listar';
import { CampanhaIncentivoVisualizar } from './pages/CampanhaIncentivo/Visualizar/Visualizar';

import { OrdemPagamentoCadastrar } from './pages/OrdemPagamento/Cadastrar/Cadastrar';
import { OrdemPagamentoEditar } from './pages/OrdemPagamento/Editar/Editar';
import { OrdemPagamentoListar } from './pages/OrdemPagamento/Listar/Listar';

import { UploadPontosCampanha } from './pages/UploadPontos/Cadastrar/Cadastrar';

import { NotificacaoBackupCadastrar } from './pages/NotificacaoBackup/Cadastrar/Cadastrar';
import { NotificacaoBackupEditar } from './pages/NotificacaoBackup/Editar/Editar';
import { NotificacaoBackupListar } from './pages/NotificacaoBackup/Listar/Listar';

import { ListarMetas } from './pages/CampanhaIncentivo/ListarMetas';
import { InformacoesExtrato } from './pages/InformacoesExtrato/InformacoesExtrato';
import { VisualizacaoInformativo } from './pages/Informativos/VisualizacaoInformativo/VisualizacaoInformativo';

import { ArquivosCampanhaCadastrar } from './pages/CampanhaIncentivo/ArquivosCampanha/ArquivosCampanhaCadastrar';
import { ArquivosCampanhaEditar } from './pages/CampanhaIncentivo/ArquivosCampanha/ArquivosCampanhaEditar';

import { DownloadBases } from './pages/DownloadBases/DownloadBases';
import { DownloadIncentivo } from './pages/DownloadIncentivo/DownloadIncentivo';

import { ImportPlanUsers } from './pages/ImportPlanUsers/ImportPlanUsers';

import { GerenciarPermissoes } from './pages/ContentPermissions/GerenciarPermissoes/GerenciarPermissoes';
import { ListUserBlock } from './pages/ContentPermissions/Listar/ListUserBlock';
import { PermissionsListUsers } from './pages/ContentPermissions/Listar/Listar';
import { CategoriasRegionais } from './pages/VejaMaisRegional/CategoriasRegionais';

import { MapaDeProcessosCadastrar } from './pages/MapaDeProcessos/Cadastrar/Cadastrar';
import { MapaDeProcessosEditar } from './pages/MapaDeProcessos/Editar/Editar';
import { MapaDeProcessosListar } from './pages/MapaDeProcessos/Listar/Listar';
import { MapaDeProcessos } from './pages/MapaDeProcessos/MapaDeProcessos';

import { EnumAnalyticsEvent, EnumAnalyticsParentKind } from './autogenerated/client/types';
import { SincronizarGU } from './pages/Admin/SincronizarGU';
import { MeuDiaNoPdv } from './pages/MeuDiaNoPdv/MeuDianoPdv';
import moment from 'moment';
import { SincronizarCargos } from './pages/Admin/SincronizarCargos';
import { ApuracaoCampanha } from './pages/CampanhaIncentivo/ApuracaoCampanha/ApuracaoCampanha';
import { CampanhaExternaEditar } from './pages/CampanhaIncentivo/CampanhasExternas/Form';
import { CampanhaExternaListar } from './pages/CampanhaIncentivo/CampanhasExternas/Listar';
import { GameficationCreate } from './pages/Gamefication/Create/Create';
import { GameficationEdit } from './pages/Gamefication/Edit/Edit';
import { GameficationList } from './pages/Gamefication/List/List';
import { Gamefication } from './pages/Gamefication/Participant/Gamefication';
import { GameficationChallengePoints } from './pages/GameficationChallengePoints/List/List';
import { GameficationNivelCreate } from './pages/GameficationNivel/Create/Create';
import { GameficationNivelEdit } from './pages/GameficationNivel/Edit/Edit';
import { GameficationNivelList } from './pages/GameficationNivel/List/List';
import { GameficationNivelParticipant } from './pages/GameficationNivel/Participant/Participant';
import { GameficationNivelCustomCreate } from './pages/GameficationNivelCustom/Create/Create';
import { GameficationNivelCustomEdit } from './pages/GameficationNivelCustom/Edit/Edit';
import { GameficationNiveCustomlList } from './pages/GameficationNivelCustom/List/List';
import { GameficationRankingCreate } from './pages/GameficationRanking/Create/Create';
import { GameficationRankingEdit } from './pages/GameficationRanking/Edit/Edit';
import { GameficationRankinglList } from './pages/GameficationRanking/List/List';
import { GameficationRankingHandler } from './pages/GameficationRanking/Ranking/RankingHandler';
import { GameficationResearchAnswersList } from './pages/GameficationResearch/AnswerList/List';
import { GameficationResearchList } from './pages/GameficationResearch/List/List';
import { GameficationQuizList } from './pages/GameficationQuiz/List/List';
import { GameficationQuizAnswersList } from './pages/GameficationQuiz/AnswerList/List';
import { GameficationMissionList } from './pages/GamificationMission/List/List';
import { GameficationVerificationList } from './pages/GamificationMission/VerificationMission/List';
import { MeuDiaNoPdvEncerrado } from './pages/MeuDiaNoPdv/MeuDianoPdvEncerrado';
import { TimTech } from './pages/TimTech/TimTech';
import { CampanhaVigenteRegulamento } from './pages/CampanhaIncentivo/CampanhaVigenteRegulamento';
import { VejaMaisInstitucionalEditar } from './pages/VejaMaisInstitucional/Editar/Editar';
import { VejaMaisInstitucionalCadastrar } from './pages/VejaMaisInstitucional/Cadastrar/Cadastrar';
import { VejaMaisInstitucionalListar } from './pages/VejaMaisInstitucional/Listar/Listar';
import { VejaMaisInstitucional } from './pages/VejaMaisInstitucional/VejaMaisInstitucional';
import { FaturaListar } from './pages/OrdemPagamento/Listar/ListarFaturas';
import { ComparaAiListar } from './pages/ComparaAi/Listar/Listar';
import { ComparaAiEditar } from './pages/ComparaAi/Editar/Editar';
import { ComparaAiCadastrar } from './pages/ComparaAi/Cadastrar/Cadastrar';
import { ComparaAi } from './pages/ComparaAi/ComparaAi';
import { Jornada } from './pages/Jornada/Jornada';
import { ApuracaoIncentivo } from './pages/CampanhaIncentivo/ApuracaoCampanha/ApuracaoIncentivo';

setAnalyticsConfig({
  app: 'site-consumer',
  containerId: 'GTM-PRG5TKK',
  extraGoogleProps: {},
});

export const App = () => {
  const isLocalHost = window.location.host.match(/localhost:/);

  const [graphqlClient] = React.useState(() => {
    return new GraphQLClient({
      url: API_URL,
      middleware: [
        async function (ctx) {
          const token = window.localStorage.getItem('azureToken');
          if (token) {
            ctx.requestConfig.headers = {
              ...ctx.requestConfig.headers,
              'azure-ad-token': token,
              'x-location-pathname': window.location.pathname, // usado no cms para capturar a url da origin da requisição
            };
          }
          return ctx;
        },
        graphqlLogsMiddleware,
        graphqlMiddleware,
      ],
      methods: AppMethods,
      methodsInfo: AppMethodsInfo,
    });
  });

  return (
    <AppReduxProvider>
      <ThemeProvider theme={siteTheme}>
        <SocketIOProvider
          url={API_DOMAIN_URL}
          opts={{
            transports: ['websocket'],
            path: '/socket.io',
          }}
        >
          <GraphQLProvider
            client={graphqlClient}
            render={(store) => {
              return (
                <DataProviders
                  store={store}
                  uploadUrl={API_DOMAIN_URL}
                  parseUserPermissions={(user) => {
                    const isTrade = user.channels?.some((el) => el === 'trade');
                    const isSuporteVendas = user.channels?.some((el) => el === 'suporte_a_vendas');
                    const isRegionHQ = user.region === 'THQ' || user.region === 'HQ';

                    const isMasterBlasterAdmin = user.roles.includes(
                      'adminPermissions_user', // pode responder comentários e bloquear usuários
                    );

                    return {
                      manageOtherUsersComments: isMasterBlasterAdmin,
                      simulateProfiles: isTrade || (isSuporteVendas && isRegionHQ),
                    };
                  }}
                >
                  <AnalyticsProvider>
                    <Routes />
                  </AnalyticsProvider>
                </DataProviders>
              );
            }}
          />
        </SocketIOProvider>
      </ThemeProvider>
    </AppReduxProvider>
  );
};

const history = createBrowserHistory();

const AnalyticsProvider = ({ children }: { children: React.ReactNode }) => {
  const analitcsPageView = useClient('AnalyticsPageView');

  React.useEffect(() => {
    const cbExternalsLink = function (event: any) {
      if (!event.target?.href) return;

      const locationHost = new URL(window.location.href).host;
      const targetHost = new URL(event.target.href).host;

      if (locationHost === targetHost) return;

      analitcsPageView.fetch({
        variables: {
          title: 'Link Externo',
          externalLink: event.target.href,
          kind: EnumAnalyticsParentKind.ExternalPageView,
        },
      });
    };

    document.addEventListener('click', cbExternalsLink);

    return () => document.removeEventListener('click', cbExternalsLink);
  }, []);

  const analytics = useAnalytics();
  const currentUrl = window.location.href;
  const accessToken = new URLSearchParams(window.location.search).get('access_token');
  const hasToken = currentUrl.split('#id_token=')[1];
  const hasSetedToken = localStorage.getItem('azureToken');

  React.useEffect(() => {
    if (accessToken) {
      UserSession.setToken(accessToken);
    }

    if (hasToken || hasSetedToken) {
      const dataUrl = hasToken?.split('&')[0] ?? hasSetedToken;
      window.localStorage.setItem('azureToken', dataUrl);

      // LOGIN AZURE ESTÁ AQUI
      const azureLoggedAt = moment(window.localStorage.getItem('azureLoggedAt') ?? '');
      const firstLogin = !azureLoggedAt.isValid();
      const daysSincelastLogin = moment().diff(azureLoggedAt, 'day');

      if (firstLogin || daysSincelastLogin === 1) {
        window.localStorage.setItem('azureLoggedAt', `${new Date()}`);
        analytics.track({
          event: EnumAnalyticsEvent.LoginAzureAd,
          kind: EnumAnalyticsParentKind.User,
          label: 'Tim',
          singleDoc: false,
        });
      }
    }
  }, [currentUrl, hasToken, hasSetedToken]);
  return <>{children}</>;
};

// ************
//  * ROUTES *
// ************
const Routes = () => {
  React.useEffect(() => {
    history.listen(function () {
      window.scroll(0, 0);
    });
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <Route path={'/sistemas'} exact component={routerPageWrapper(SistemasVendas2020)} />
        <Route path={routes.home.path} exact component={routerPageWrapper(Home)} />
        {/* Duplicado */}
        <Route path={routes.homePortal.path} exact component={routerPageWrapper(Home)} />
        <Route path={routes.login.path} exact component={routerPageWrapper(Login)} />
        <Route path={routes.EmConstrucao.path} exact component={routerPageWrapper(Login)} />
        <Route path={routes.meuDiaNoPdv.path} exact component={routerPageWrapper(MeuDiaNoPdvEncerrado)} />
        {/* <Route path={routes.meuDiaNoPdv.path} exact component={routerPageWrapper(MeuDiaNoPdv)} /> */}

        <Route path={routes.canalEditar.path} exact component={routerPageWrapper(VejaMaisCanalEditar)} />

        <Route path={routes.smTabelaPrecos.path} exact component={routerPageWrapper(TabelaPrecos)} />
        <Route path={routes.tabelaPrecoListar.path} exact component={routerPageWrapper(TabelaPrecoListar)} />

        <Route path={routes.informativos.path} exact component={routerPageWrapper(Informativos)} />
        <Route path={routes.catInformativos.path} exact component={routerPageWrapper(Informativos)} />
        <Route path={routes.catProdinformativos.path} exact component={routerPageWrapper(Informativos)} />
        <Route path={routes.busca.path} exact component={routerPageWrapper(Busca)} />

        <Route path={routes.OnTube.path} exact component={routerPageWrapper(OnTube)} />
        <Route path={routes.OnTubePlayer.path} exact component={routerPageWrapper(OnTubePlayer)} />
        <Route path={routes.OnTubePlaylist.path} exact component={routerPageWrapper(OnTubePlaylist)} />
        <Route path={routes.OnTubeListar.path} exact component={routerPageWrapper(OnTubeListar)} />

        <Route path={routes.catProcessos.path} exact component={routerPageWrapper(Processos)} />
        <Route path={routes.catProdProcessos.path} exact component={routerPageWrapper(Processos)} />

        <Route path={routes.processosListar.path} exact component={routerPageWrapper(ProcessosGerenciar)} />

        <Route path={routes.sistemasVendas.path} exact component={routerPageWrapper(SistemasVendas2020)} />

        <Route path={routes.sistemasVendasListar.path} exact component={routerPageWrapper(SistemaVendasListar)} />
        <Route path={routes.sistemasVendasEditar.path} exact component={routerPageWrapper(SistemaVendasEditar)} />
        <Route path={routes.sistemasVendasCadastrar.path} exact component={routerPageWrapper(SistemaVendasCadastrar)} />
        <Route path={routes.EmConstrucao.path} exact component={routerPageWrapper(Login)} />

        <Route path={routes.processosListar.path} exact component={routerPageWrapper(ProcessosGerenciar)} />

        <Route path={routes.mapaDeOfertas.path} exact component={routerPageWrapper(MapaDeOfertas)} />
        <Route path={routes.mapaDeOfertaListar.path} exact component={routerPageWrapper(MapaDeOfertasListar)} />
        <Route path={routes.tabelaPrecoListar.path} exact component={routerPageWrapper(TabelaPrecoListar)} />

        <Route path={routes.OnTubeListar.path} exact component={routerPageWrapper(OnTubeListar)} />
        <Route path={routes.regulamento.path} exact component={routerPageWrapper(Regulamentos)} />
        <Route path={routes.regulamentoListar.path} exact component={routerPageWrapper(RegulamentoListar)} />
        <Route path={routes.taNaMaoListar.path} exact component={routerPageWrapper(TaNaMaoListar)} />
        <Route path={routes.canalListar.path} exact component={routerPageWrapper(VejaMaisCanalListar)} />
        <Route path={routes.canal.path} exact component={routerPageWrapper(VejaMaisCanal)} />
        <Route
          path={routes.institucionalListar.path}
          exact
          component={routerPageWrapper(VejaMaisInstitucionalListar)}
        />

        <Route path={routes.institucional.path} exact component={routerPageWrapper(VejaMaisInstitucional)} />
        <Route path={routes.taNaMao.path} exact component={routerPageWrapper(TaNaMao)} />
        <Route path={routes.regionalListar.path} exact component={routerPageWrapper(RegionalListar)} />
        <Route path={routes.regional.path} exact component={routerPageWrapper(Regional)} />
        <Route path={routes.selectFilter.path} exact component={routerPageWrapper(SelecaoCategoria)} />
        <Route path={routes.materiaisPDV.path} exact component={routerPageWrapper(MateriaisPDV)} />
        <Route path={routes.materiaisPDVListar.path} exact component={routerPageWrapper(MapaPDVListar)} />
        <Route path={routes.materiaisPDVEditar.path} exact component={routerPageWrapper(MapaPDVEditar)} />
        <Route path={routes.materiaisPDVCadastrar.path} exact component={routerPageWrapper(MapaPDVCadastrar)} />
        <Route path={routes.selectFilterPDV.path} exact component={routerPageWrapper(MapaPDVCategorias)} />
        <Route path={routes.pdfViewer.path} exact component={routerPageWrapper(PageLoadPDF)} />
        <Route path={routes.htmlViewer.path} exact component={routerPageWrapper(PageLoadHTML)} />
        <Route path={routes.imageViewer.path} exact component={routerPageWrapper(ImageViewer)} />
        <Route path={routes.liveListar.path} exact component={routerPageWrapper(LiveListar)} />
        <Route path={routes.informativosListar.path} exact component={routerPageWrapper(InformativosListar)} />
        <Route
          path={routes.comparadorAparelhosListar.path}
          exact
          component={routerPageWrapper(ComparadorAparelhosListar)}
        />
        {/* <Route
          path={routes.smComparadorAparelhos.path}
          exact
          component={routerPageWrapper(ComparadorAparelhos)}
        /> */}

        <Route path={routes.argumentacaoFDVListar.path} exact component={routerPageWrapper(ArgumentacaoFDVListar)} />
        <Route path={routes.argumentacaoFDV.path} exact component={routerPageWrapper(ArgumentacaoFDV)} />

        <Route path={routes.comparaAiListar.path} exact component={routerPageWrapper(ComparaAiListar)} />
        <Route path={routes.comparaAi.path} exact component={routerPageWrapper(ComparaAi)} />

        <Route path={routes.mapaPecasListar.path} exact component={routerPageWrapper(MapaPecasListar)} />
        <Route path={routes.mapaPecas.path} exact component={routerPageWrapper(MapaPecas)} />
        <Route path={routes.materiaisApoio.path} exact component={routerPageWrapper(MateriaisApoio)} />
        <Route path={routes.materiaisApoioListar.path} exact component={routerPageWrapper(MateriaisApoioListar)} />

        <Route path={routes.tabelaPrecoCadastrar.path} exact component={routerPageWrapper(TabelaPrecoCadastrar)} />

        <Route path={routes.tabelaPrecoEditar.path} exact component={routerPageWrapper(TabelaPrecoEditar)} />

        <Route path={routes.OnTubeCadastrar.path} exact component={routerPageWrapper(OnTubeCadastrar)} />

        <Route path={routes.OnTubeEditar.path} exact component={routerPageWrapper(OnTubeEditar)} />

        <Route path={routes.processosCadastrar.path} exact component={routerPageWrapper(ProcessosCadastrar)} />

        <Route path={routes.processosEditar.path} exact component={routerPageWrapper(ProcessosEditar)} />

        <Route path={routes.regulamentoEditar.path} exact component={routerPageWrapper(RegulamentoEditar)} />

        <Route path={routes.regulamentoCadastrar.path} exact component={routerPageWrapper(RegulamentoCadastrar)} />

        <Route path={routes.mapaDeOfertaEditar.path} exact component={routerPageWrapper(MapaDeOfertasEditar)} />

        <Route path={routes.mapaDeOfertaCadastrar.path} exact component={routerPageWrapper(MapaDeOfertasCadastrar)} />

        <Route path={routes.conteudoMaterial.path} exact component={routerPageWrapper(ConteudoHTML)} />

        <Route path={routes.canalCadastrar.path} exact component={routerPageWrapper(VejaMaisCanalCadastrar)} />

        <Route
          path={routes.institucionalEditar.path}
          exact
          component={routerPageWrapper(VejaMaisInstitucionalEditar)}
        />

        <Route
          path={routes.institucionalCadastrar.path}
          exact
          component={routerPageWrapper(VejaMaisInstitucionalCadastrar)}
        />

        <Route path={routes.taNaMaoEditar.path} exact component={routerPageWrapper(TaNaMaoEditar)} />

        <Route path={routes.regionalCadastrar.path} exact component={routerPageWrapper(RegionalCadastrar)} />

        <Route path={routes.regionalEditar.path} exact component={routerPageWrapper(RegionalEditar)} />

        <Route path={routes.taNaMaoCadastrar.path} exact component={routerPageWrapper(TaNaMaoCadastrar)} />

        <Route path={routes.liveEditar.path} exact component={routerPageWrapper(LiveEditar)} />

        <Route path={routes.liveCadastrar.path} exact component={routerPageWrapper(LiveCadastrar)} />

        <Route path={routes.informativosEditar.path} exact component={routerPageWrapper(InformativosEditar)} />

        <Route path={routes.informativosDuplicar.path} exact component={routerPageWrapper(InformativosDuplicar)} />

        <Route path={routes.informativosCadastrar.path} exact component={routerPageWrapper(InformativosCadastrar)} />

        <Route
          path={routes.comparadorAparelhosEditar.path}
          exact
          component={routerPageWrapper(ComparadorAparelhosEditar)}
        />

        <Route
          path={routes.comparadorAparelhosCadastrar.path}
          exact
          component={routerPageWrapper(ComparadorAparelhosCadastrar)}
        />

        <Route path={routes.argumentacaoFDVEditar.path} exact component={routerPageWrapper(ArgumentacaoFDVEditar)} />

        <Route
          path={routes.argumentacaoFDVCadastrar.path}
          exact
          component={routerPageWrapper(ArgumentacaoFDVCadastrar)}
        />

        <Route path={routes.comparaAiEditar.path} exact component={routerPageWrapper(ComparaAiEditar)} />

        <Route path={routes.comparaAiCadastrar.path} exact component={routerPageWrapper(ComparaAiCadastrar)} />

        <Route path={routes.mapaPecasEditar.path} exact component={routerPageWrapper(MapaPecasEditar)} />

        <Route path={routes.mapaPecasCadastrar.path} exact component={routerPageWrapper(MapaPecasCadastrar)} />

        <Route path={routes.materiaisApoioEditar.path} exact component={routerPageWrapper(MateriaisApoioEditar)} />

        <Route
          path={routes.materiaisApoioCadastrar.path}
          exact
          component={routerPageWrapper(MateriaisApoioCadastrar)}
        />

        <Route path={routes.materiaisTimEmpresas.path} exact component={routerPageWrapper(MateriaisTimEmpresas)} />

        <Route
          path={routes.materiaisTimEmpresasListar.path}
          exact
          component={routerPageWrapper(MateriaisTimEmpresasListar)}
        />

        <Route
          path={routes.materiaisTimEmpresasEditar.path}
          exact
          component={routerPageWrapper(MateriaisTimEmpresasEditar)}
        />

        <Route
          path={routes.materiaisTimEmpresasCadastrar.path}
          exact
          component={routerPageWrapper(MateriaisTimEmpresasCadastrar)}
        />

        <Route path={routes.bannersListar.path} exact component={routerPageWrapper(BannerListar)} />

        <Route path={routes.bannersCadastrar.path} exact component={routerPageWrapper(BannerCadastrar)} />

        <Route path={routes.bannersEditar.path} exact component={routerPageWrapper(BannerEditar)} />

        <Route path={routes.relatorios.path} exact component={routerPageWrapper(Relatorios)} />

        {/* <Route path={routes.relatorioAceites.path} exact component={routerPageWrapper(RelatoriosAceites)} /> */}

        <Route path={routes.notificacoesListar.path} exact component={routerPageWrapper(ListarNotificacoes)} />

        <Route path={routes.notificacoesCadastrar.path} exact component={routerPageWrapper(CadastrarNotificacao)} />

        <Route path={routes.notificacoesEditar.path} exact component={routerPageWrapper(EditarNotificacao)} />

        <Route path={routes.notificacoes.path} exact component={routerPageWrapper(Notificacoes)} />

        <Route path={routes.atividadesRecentes.path} exact component={routerPageWrapper(AtividadesRecentes)} />

        <Route path={routes.favoritos.path} exact component={routerPageWrapper(Favoritos)} />

        <Route path={routes.pastaFavoritos.path} exact component={routerPageWrapper(PastaFavoritos)} />
        <Route path={routes.contestacaoEditar.path} exact component={routerPageWrapper(ContestacaoEditar)} />

        <Route path={routes.contestacaoListar.path} exact component={routerPageWrapper(ContestacaoListar)} />
        <Route
          path={routes.campanhasIncentivoVigenteOld.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoVigenteOld)}
        />

        <Route
          path={routes.campanhasIncentivoVigente.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoVigente)}
        />
        <Route path={routes.listarMetas.path} exact component={routerPageWrapper(ListarMetas)} />

        <Route path={routes.campanhasIncentivoVigentes.path} exact component={routerPageWrapper(CampanhaIncentivo)} />

        <Route
          path={routes.campanhasIncentivoListar.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoListar)}
        />

        <Route
          path={routes.campanhasIncentivoVisualizar.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoVisualizar)}
        />

        <Route
          path={routes.campanhasIncentivoEditar.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoEditar)}
        />

        <Route
          path={routes.campanhasIncentivoCadastrar.path}
          exact
          component={routerPageWrapper(CampanhaIncentivoCadastrar)}
        />

        <Route path={routes.campanhasIncentivoApuracao.path} exact component={routerPageWrapper(ApuracaoIncentivo)} />

        <Route path={routes.distribuicaoPontos.path} exact component={routerPageWrapper(DistribuicaoPontos)} />

        <Route
          path={routes.notificacaoBackupListar.path}
          exact
          component={routerPageWrapper(NotificacaoBackupListar)}
        />

        <Route
          path={routes.notificacaoBackupEditar.path}
          exact
          component={routerPageWrapper(NotificacaoBackupEditar)}
        />

        <Route
          path={routes.notificacaoBackupCadastrar.path}
          exact
          component={routerPageWrapper(NotificacaoBackupCadastrar)}
        />

        <Route path={routes.informacoesExtrato.path} exact component={routerPageWrapper(InformacoesExtrato)} />

        <Route path={routes.faturaListar.path} exact component={routerPageWrapper(FaturaListar)} />

        <Route
          path={routes.ordemPagamentoCadastrar.path}
          exact
          component={routerPageWrapper(OrdemPagamentoCadastrar)}
        />

        <Route path={routes.ordemPagamentoListar.path} exact component={routerPageWrapper(OrdemPagamentoListar)} />

        <Route path={routes.ordemPagamentoEditar.path} exact component={routerPageWrapper(OrdemPagamentoEditar)} />

        <Route
          path={routes.campanhasIncentivoUploadPontos.path}
          exact
          component={routerPageWrapper(UploadPontosCampanha)}
        />

        <Route path={routes.visualizarInformativo.path} exact component={routerPageWrapper(VisualizacaoInformativo)} />

        <Route path={routes.visualizarArquivosCampanha.path} exact component={routerPageWrapper(ApuracaoCampanha)} />
        <Route
          path={routes.cadastrarArquivosCampanha.path}
          exact
          component={routerPageWrapper(ArquivosCampanhaCadastrar)}
        />

        <Route path={routes.editarArquivosCampanha.path} exact component={routerPageWrapper(ArquivosCampanhaEditar)} />

        <Route path={routes.downloadBases.path} exact component={routerPageWrapper(DownloadBases)} />

        <Route path={routes.importPlanUsers.path} exact component={routerPageWrapper(ImportPlanUsers)} />

        <Route path={routes.downloadIncentivo.path} exact component={routerPageWrapper(DownloadIncentivo)} />

        <Route path={routes.gerenciarPermissoesListar.path} exact component={routerPageWrapper(PermissionsListUsers)} />

        <Route path={routes.gerenciarPermissoesEditar.path} exact component={routerPageWrapper(GerenciarPermissoes)} />

        <Route path={routes.acompanharContestacao.path} exact component={routerPageWrapper(AcompanharContestacao)} />

        <Route path={routes.listUserBlock.path} exact component={routerPageWrapper(ListUserBlock)} />

        <Route path={routes.regionais.path} exact component={routerPageWrapper(CategoriasRegionais)} />

        <Route path={routes.mapaDeProcessos.path} exact component={routerPageWrapper(MapaDeProcessos)} />
        <Route path={routes.mapaDeProcessosListar.path} exact component={routerPageWrapper(MapaDeProcessosListar)} />
        <Route path={routes.mapaDeProcessosEditar.path} exact component={routerPageWrapper(MapaDeProcessosEditar)} />
        <Route
          path={routes.mapaDeProcessosCadastrar.path}
          exact
          component={routerPageWrapper(MapaDeProcessosCadastrar)}
        />

        <Route path={routes.sincronizarGu.path} exact component={routerPageWrapper(SincronizarGU)} />
        <Route path={routes.sincronizarCargosGu.path} exact component={routerPageWrapper(SincronizarCargos)} />

        <Route path={routes.gameficationList.path} exact component={routerPageWrapper(GameficationList)} />
        <Route path={routes.gameficationCreate.path} exact component={routerPageWrapper(GameficationCreate)} />
        <Route path={routes.gameficationEdit.path} exact component={routerPageWrapper(GameficationEdit)} />

        <Route path={routes.gameficationNivelList.path} exact component={routerPageWrapper(GameficationNivelList)} />
        <Route
          path={routes.gameficationNivelCreate.path}
          exact
          component={routerPageWrapper(GameficationNivelCreate)}
        />
        <Route path={routes.gameficationNivelEdit.path} exact component={routerPageWrapper(GameficationNivelEdit)} />
        <Route
          path={routes.gameficationNivelParticipant.path}
          exact
          component={routerPageWrapper(GameficationNivelParticipant)}
        />
        <Route
          path={routes.gameficationResearchesList.path}
          exact
          component={routerPageWrapper(GameficationResearchList)}
        />
        <Route
          path={routes.gameficationResearchesAnswersList.path}
          exact
          component={routerPageWrapper(GameficationResearchAnswersList)}
        />
        <Route path={routes.gameficationQuizList.path} exact component={routerPageWrapper(GameficationQuizList)} />
        <Route
          path={routes.gameficationQuizAnswersList.path}
          exact
          component={routerPageWrapper(GameficationQuizAnswersList)}
        />
        <Route path={routes.gamefication.path} exact component={routerPageWrapper(Gamefication)} />

        <Route path={routes.gameficationMissions.path} exact component={routerPageWrapper(GameficationMissionList)} />
        <Route
          path={routes.gameficationVerificationMissions.path}
          exact
          component={routerPageWrapper(GameficationVerificationList)}
        />

        <Route
          path={routes.gameficationRankingList.path}
          exact
          component={routerPageWrapper(GameficationRankinglList)}
        />
        <Route
          path={routes.gameficationRankingCreate.path}
          exact
          component={routerPageWrapper(GameficationRankingCreate)}
        />
        <Route
          path={routes.gameficationRankingEdit.path}
          exact
          component={routerPageWrapper(GameficationRankingEdit)}
        />

        <Route
          path={routes.gameficationRankingParticipant.path}
          exact
          component={routerPageWrapper(GameficationRankingHandler)}
        />

        <Route
          path={routes.gameficationRankingAdmin.path}
          exact
          component={routerPageWrapper(GameficationRankingHandler)}
        />

        <Route
          path={routes.gameficationChallengePoints.path}
          exact
          component={routerPageWrapper(GameficationChallengePoints)}
        />

        <Route
          path={routes.gameficationNivelCustomList.path}
          exact
          component={routerPageWrapper(GameficationNiveCustomlList)}
        />
        <Route
          path={routes.gameficationNivelCustomCreate.path}
          exact
          component={routerPageWrapper(GameficationNivelCustomCreate)}
        />
        <Route
          path={routes.gameficationNivelCustomEdit.path}
          exact
          component={routerPageWrapper(GameficationNivelCustomEdit)}
        />
        <Route path={routes.timTech.path} exact component={routerPageWrapper(TimTech)} />
        <Route path={routes.jornada.path} exact component={routerPageWrapper(Jornada)} />
        <Route
          path={routes.campanhasIncentivoExternas.path}
          exact
          component={routerPageWrapper(CampanhaExternaListar)}
        />
        <Route
          path={routes.campanhasIncentivoExternasEditar.path}
          exact
          component={routerPageWrapper(CampanhaExternaEditar)}
        />
        <Route
          path={routes.campanhasIncentivoVigenteRegulamento.path}
          exact
          component={routerPageWrapper(CampanhaVigenteRegulamento)}
        />

        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};
