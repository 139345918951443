"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function removeAccent(text) {
  var result = text;
  var mapaAccentsHex = {
    a: /[\xE0-\xE6]/g,
    A: /[\xC0-\xC6]/g,
    e: /[\xE8-\xEB]/g,
    E: /[\xC8-\xCB]/g,
    i: /[\xEC-\xEF]/g,
    I: /[\xCC-\xCF]/g,
    o: /[\xF2-\xF6]/g,
    O: /[\xD2-\xD6]/g,
    u: /[\xF9-\xFC]/g,
    U: /[\xD9-\xDC]/g,
    c: /\xE7/g,
    C: /\xC7/g,
    n: /\xF1/g,
    N: /\xD1/g
  };

  for (var str in mapaAccentsHex) {
    var regularExp = mapaAccentsHex[str];
    result = result.replace(regularExp, str);
  }

  return result;
}

exports.removeAccent = removeAccent;

function normalizeText(text) {
  return removeAccent(text.toLowerCase());
}

exports.normalizeText = normalizeText;