import { Button, LoadingIndicator } from '@digi-tim-19/components';
import { Avatar } from 'antd';
import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import { formatNumberToPtBr } from '../../utils/formatters';

const MiniProfileContainer = styled.div`
  width: 100%;
  diplay: flex;
  margin-bottom: 15px;
`;

const ProfileBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-itens: center;
`;

const ImageContainer = styled.div<{ menuIsOpen?: boolean }>`
  width: 60px;
  height: 79px;
  img {
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    width: 100%;
    border: 2px solid #001ba3;
  }
  p {
    font-size: 8px;
    cursor: pointer;
    color: #dc3545;
    margin-left: 8px;
  }
  ${(props) => props.menuIsOpen && 'margin-left: 15px;'}
`;

const DescriptionContainer = styled.div`
  flex: 1;
  padding-left: 15px;
`;

const TitleName = styled.strong`
  display: -webkit-box;
  font-size: 17px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-bottom: -6px;
`;

const Profession = styled.span`
  font-size: 9px;
  padding: 2px 4px;
  border: 1px solid #001ba3;
  border-radius: 5px;
`;

const ChangeProfile = styled.div`
  display: flex;
  font-size: 10px;

  button {
    padding: 0;
    margin-left: 5px;
    font-size: 10px;
    line-height: 0 !important;
    height: 20px !important;
  }
`;

const PointsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`;

const PointsBox = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row-reverse;
  flex: 1;
  color: ${(props) => props.theme.red};
  align-items: center;
  gap: 7px;
  padding-left: 15px;

  span {
    font-size: 20pt;
  }

  img {
    width: 30px;
    height: 30px;
  }
`;

const PointsButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  button {
    height: 20px;
    font-size: 9pt;
  }
`;

const LinkBottomRight = styled.div`
  a {
    color: ${(props) => props.theme.blue};
    font-size: 9pt;
    float: right;
    margin-right: 14px;
    margin-bottom: -10px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

type MiniProfileProp = {
  id: string;
  img?: string;
  alt?: string;
  name: string;
  profession: string;
  dots?: number;
  menuIsOpen?: boolean;
  showLinksBotton?: boolean;
  backgroundProfile?: string;
  onClickEditProfile?: Function;
  onClickResgate?: Function;
  onClickExtrato?: Function;
  showChangeProfile?: Boolean;
  ProfileName?: string;
  onClickChangeProfile?: Function;
  isLoadingChangeUser?: Boolean;
};

export const MiniProfile = ({
  img,
  alt,
  name,
  profession,
  dots = 0,
  menuIsOpen,
  onClickEditProfile,
  onClickResgate,
  backgroundProfile,
  onClickExtrato,
  showChangeProfile,
  ProfileName,
  onClickChangeProfile,
  isLoadingChangeUser,
}: MiniProfileProp) => {
  return (
    <MiniProfileContainer>
      <ProfileBox>
        <ImageContainer menuIsOpen={menuIsOpen}>
          {img ? <img src={img} alt={alt} /> : <Avatar size={60} icon="user" />}
          {menuIsOpen && <p onClick={() => onClickEditProfile && onClickEditProfile()}>Editar perfil</p>}
        </ImageContainer>
        {menuIsOpen && (
          <DescriptionContainer>
            <TitleName>Olá, {name}</TitleName>
            <Profession>{profession}</Profession>
            {showChangeProfile === true && (
              <ChangeProfile>
                <span>
                  Trocar perfil para:
                  {!!isLoadingChangeUser ? (
                    <LoadingIndicator small />
                  ) : (
                    <Button
                      type="link"
                      onClick={() => {
                        if (onClickChangeProfile) onClickChangeProfile();
                      }}
                    >
                      {ProfileName || 'Tá Na Rede'}
                    </Button>
                  )}
                </span>
              </ChangeProfile>
            )}
          </DescriptionContainer>
        )}
      </ProfileBox>
      {menuIsOpen && dots! >= 0 && (
        <PointsContainer>
          <PointsBox>
            <span>{formatNumberToPtBr(dots)}</span>
            <img src="/defaultImages/gamefication/coin.png" alt="TIMCOINS" />
          </PointsBox>
          <PointsButtonBox>
            <Button
              onClick={(e) => onClickResgate && onClickResgate(e)}
              size="small"
              style={{ background: backgroundProfile }}
            >
              RESGATAR
            </Button>
          </PointsButtonBox>
        </PointsContainer>
      )}
      {menuIsOpen && onClickExtrato && (
        <LinkBottomRight className={cx('LinkBottom')}>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();

              if (onClickExtrato) onClickExtrato(e);
            }}
          >
            {`Ver extrato >`}
          </a>
        </LinkBottomRight>
      )}
    </MiniProfileContainer>
  );
};
