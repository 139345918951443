"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var DefaultMimeTypes = ['text/csv', 'application/msword', 'image/gif', 'image/jpeg', 'application/vnd.oasis.opendocument.spreadshee', 'image/png', 'application/pdf', 'application/vnd.ms-excel', 'officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.presentationml.template', 'image/vnd.adobe.photoshop', 'application/vnd.ms-powerpoint', 'video/avi', 'video/mp4', 'text/plain', 'application/zip', 'application/x-zip-compressed', 'application/octet-stream', 'image/photoshop', 'image/x-photoshop', 'image/psd', 'application/x-photoshop', 'application/photoshop', 'application/psd', 'image/psd', 'drawing/x-dwf', 'model/vnd.dwf', 'application/acad', 'application/cdr', 'application/coreldraw', 'application/x-cdr', 'application/vnd.ms-powerpoint.presentation.macroEnabled.12', 'application/vnd.ms-excel.sheet.macroEnabled.12', 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'];

var useMimeType = function useMimeType() {
  var supportedExtension = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DefaultMimeTypes;

  var hasType = function hasType(type) {
    return supportedExtension.includes(type);
  };

  return {
    hasType: hasType
  };
};

exports.default = useMimeType;