"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var image_extensions_1 = require("../image-extensions");

exports.consumerRoutes = {
  example: {
    path: '/example/:example_id',
    mount: function mount(example_id) {
      return "/custom-example/".concat(example_id);
    }
  },
  home: {
    path: '/home',
    mount: function mount() {
      return '/home';
    }
  },
  homePortal: {
    path: '/portal',
    mount: function mount() {
      return '/portal';
    }
  },
  login: {
    path: '/',
    mount: function mount() {
      return '/';
    }
  },
  EmConstrucao: {
    path: '/em-construcao',
    mount: function mount() {
      return '/em-construcao';
    }
  },
  meuDiaNoPdv: {
    path: '/open/meudianopdv',
    mount: function mount() {
      return "/open/meudianopdv";
    }
  },
  catInformativos: {
    path: '/categoria/informativos/:category',
    mount: function mount(category) {
      return "/categoria/informativos/".concat(category, "/");
    }
  },
  catProdinformativos: {
    path: '/categoria/informativos/:category/:product/',
    mount: function mount(category, product) {
      return "/categoria/informativos/".concat(category, "/").concat(product);
    }
  },
  informativos: {
    path: '/informativos',
    mount: function mount() {
      return "/informativos";
    }
  },
  busca: {
    path: '/busca/:search',
    mount: function mount(search) {
      return "/busca/".concat(search);
    }
  },
  OnTube: {
    path: '/ontube',
    mount: function mount() {
      return '/ontube';
    }
  },
  OnTubePlayer: {
    path: '/ontube/:id',
    mount: function mount(id) {
      return "/ontube/".concat(id);
    }
  },
  OnTubePlaylist: {
    path: '/ontube/playlist/:id',
    mount: function mount(id) {
      return "/ontube/playlist/".concat(id);
    }
  },
  OnTubeListar: {
    path: '/gerenciar/ontube/listar',
    mount: function mount() {
      return '/gerenciar/ontube/listar';
    }
  },
  OnTubeCadastrar: {
    path: '/gerenciar/ontube/cadastrar',
    mount: function mount() {
      return '/gerenciar/ontube/cadastrar';
    }
  },
  OnTubeEditar: {
    path: '/gerenciar/ontube/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/ontube/editar/".concat(id);
    }
  },
  catProcessos: {
    path: '/categoria/processos/:category',
    mount: function mount(_, category) {
      return "/categoria/processos/".concat(category);
    }
  },
  catProdProcessos: {
    path: '/categoria/processos/:category/:product',
    mount: function mount(category, product) {
      return "/categoria/processos/".concat(category, "/").concat(product);
    }
  },
  processosListar: {
    path: '/gerenciar/processos/listar',
    mount: function mount() {
      return '/gerenciar/processos/listar';
    }
  },
  processosCadastrar: {
    path: '/gerenciar/processos/cadastrar',
    mount: function mount() {
      return '/gerenciar/processos/cadastrar';
    }
  },
  processosEditar: {
    path: '/gerenciar/processos/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/processos/editar/".concat(id);
    }
  },
  conteudoMaterial: {
    path: '/material/conteudo/:id',
    mount: function mount(id) {
      return "/material/conteudo/".concat(id);
    }
  },
  taNaMao: {
    path: '/veja-mais/ta-na-mao',
    mount: function mount() {
      return "/veja-mais/ta-na-mao";
    }
  },
  taNaMaoListar: {
    path: '/gerenciar/ta-na-mao/listar',
    mount: function mount() {
      return '/gerenciar/ta-na-mao/listar';
    }
  },
  taNaMaoEditar: {
    path: '/gerenciar/ta-na-mao/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/ta-na-mao/editar/".concat(id);
    }
  },
  taNaMaoCadastrar: {
    path: '/gerenciar/ta-na-mao/cadastrar',
    mount: function mount() {
      return '/gerenciar/ta-na-mao/cadastrar';
    }
  },
  regional: {
    path: '/veja-mais/regional',
    mount: function mount() {
      return "/veja-mais/regional";
    }
  },
  regionalListar: {
    path: '/gerenciar/regional/listar/:kind',
    mount: function mount(kind) {
      return "/gerenciar/regional/listar/".concat(kind);
    }
  },
  regionalEditar: {
    path: '/gerenciar/regional/editar/:kind/:id',
    mount: function mount(kind, id) {
      return "/gerenciar/regional/editar/".concat(kind, "/").concat(id);
    }
  },
  regionalCadastrar: {
    path: '/gerenciar/regional/:kind/cadastrar',
    mount: function mount(kind) {
      return "/gerenciar/regional/".concat(kind, "/cadastrar");
    }
  },
  canal: {
    path: '/veja-mais/canal',
    mount: function mount() {
      return "/veja-mais/canal";
    }
  },
  canalListar: {
    path: '/gerenciar/veja-mais/canal/listar',
    mount: function mount() {
      return '/gerenciar/veja-mais/canal/listar';
    }
  },
  canalEditar: {
    path: '/gerenciar/veja-mais/canal/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/veja-mais/canal/editar/".concat(id);
    }
  },
  canalCadastrar: {
    path: '/gerenciar/veja-mais/canal/cadastrar',
    mount: function mount() {
      return '/gerenciar/veja-mais/canal/cadastrar';
    }
  },
  institucional: {
    path: '/veja-mais/institucional',
    mount: function mount() {
      return "/veja-mais/institucional";
    }
  },
  institucionalListar: {
    path: '/gerenciar/veja-mais/institucional/listar',
    mount: function mount() {
      return '/gerenciar/veja-mais/institucional/listar';
    }
  },
  institucionalEditar: {
    path: '/gerenciar/veja-mais/institucional/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/veja-mais/institucional/editar/".concat(id);
    }
  },
  institucionalCadastrar: {
    path: '/gerenciar/veja-mais/institucional/cadastrar',
    mount: function mount() {
      return '/gerenciar/veja-mais/institucional/cadastrar';
    }
  },
  sistemasVendas: {
    path: '/sistemas-de-vendas',
    mount: function mount() {
      return '/sistemas-de-vendas';
    }
  },
  sistemasVendasListar: {
    path: '/gerenciar/sistemas-de-vendas/listar',
    mount: function mount() {
      return '/gerenciar/sistemas-de-vendas/listar';
    }
  },
  sistemasVendasEditar: {
    path: '/gerenciar/sistemas-de-vendas/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/sistemas-de-vendas/editar/".concat(id);
    }
  },
  sistemasVendasCadastrar: {
    path: '/gerenciar/sistemas-de-vendas/cadastrar',
    mount: function mount() {
      return '/gerenciar/sistemas-de-vendas/cadastrar';
    }
  },
  selectFilter: {
    path: '/selecao/:category/:product',
    mount: function mount(category, product) {
      return "/selecao/".concat(category, "/").concat(product);
    }
  },
  materiaisPDV: {
    path: '/materiais-pdv',
    mount: function mount() {
      return '/materiais-pdv';
    }
  },
  selectFilterPDV: {
    path: '/materiais-pdv/:product',
    mount: function mount(product) {
      return "/materiais-pdv/".concat(product);
    }
  },
  materiaisPDVListar: {
    path: '/gerenciar/:category/listar/:product',
    mount: function mount(category, product) {
      return "/gerenciar/".concat(category, "/listar/").concat(product);
    }
  },
  materiaisPDVEditar: {
    path: '/gerenciar/:category/:product/editar/:id',
    mount: function mount(category, product, id) {
      return "/gerenciar/".concat(category, "/").concat(product, "/editar/").concat(id);
    }
  },
  materiaisPDVCadastrar: {
    path: '/gerenciar/:category/cadastrar/:product',
    mount: function mount(category, product) {
      return "/gerenciar/".concat(category, "/cadastrar/").concat(product);
    }
  },
  regulamento: {
    path: '/categoria/regulamento/:category/:product',
    mount: function mount(category, product) {
      return "/categoria/regulamento/".concat(category, "/").concat(product);
    }
  },
  regulamentoListar: {
    path: '/gerenciar/regulamento/listar',
    mount: function mount() {
      return '/gerenciar/regulamento/listar';
    }
  },
  regulamentoEditar: {
    path: '/gerenciar/regulamento/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/regulamento/editar/".concat(id);
    }
  },
  regulamentoCadastrar: {
    path: '/gerenciar/regulamento/cadastrar',
    mount: function mount() {
      return '/gerenciar/regulamento/cadastrar';
    }
  },
  mapaDeOfertas: {
    path: '/mapa-de-ofertas',
    mount: function mount() {
      return '/mapa-de-ofertas';
    }
  },
  mapaDeOfertaListar: {
    path: '/gerenciar/mapa-de-oferta/listar',
    mount: function mount() {
      return '/gerenciar/mapa-de-oferta/listar';
    }
  },
  mapaDeOfertaEditar: {
    path: '/gerenciar/mapa-de-oferta/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/mapa-de-oferta/editar/".concat(id);
    }
  },
  mapaDeOfertaCadastrar: {
    path: '/gerenciar/mapa-de-oferta/cadastrar',
    mount: function mount() {
      return '/gerenciar/mapa-de-oferta/cadastrar';
    }
  },
  pdfViewer: {
    path: '/visualizar/:id',
    mount: function mount(id) {
      return "/visualizar/".concat(id);
    }
  },
  htmlViewer: {
    path: '/visualizar-html/:id',
    mount: function mount(id) {
      return "/visualizar-html/".concat(id);
    }
  },
  imageViewer: {
    path: '/visualizar/image/:id',
    mount: function mount(id) {
      return "/visualizar/image/".concat(id);
    }
  },
  liveListar: {
    path: '/gerenciar/live/listar',
    mount: function mount() {
      return '/gerenciar/live/listar';
    }
  },
  liveEditar: {
    path: '/gerenciar/live/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/live/editar/".concat(id);
    }
  },
  liveCadastrar: {
    path: '/gerenciar/live/cadastrar',
    mount: function mount() {
      return '/gerenciar/live/cadastrar';
    }
  },
  informativosListar: {
    path: '/gerenciar/informativos/listar',
    mount: function mount() {
      return '/gerenciar/informativos/listar';
    }
  },
  informativosEditar: {
    path: '/gerenciar/informativos/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/informativos/editar/".concat(id);
    }
  },
  informativosDuplicar: {
    path: '/gerenciar/informativos/duplicar/:id',
    mount: function mount(id) {
      return "/gerenciar/informativos/duplicar/".concat(id);
    }
  },
  informativosCadastrar: {
    path: '/gerenciar/informativos/cadastrar',
    mount: function mount() {
      return '/gerenciar/informativos/cadastrar';
    }
  },
  smTabelaPrecos: {
    path: '/smartphone-news/tabela-de-preco',
    mount: function mount() {
      return '/smartphone-news/tabela-de-preco';
    }
  },
  tabelaPrecoListar: {
    path: '/gerenciar/smartphone-news/tabela-de-preco/listar',
    mount: function mount() {
      return '/gerenciar/smartphone-news/tabela-de-preco/listar';
    }
  },
  tabelaPrecoCadastrar: {
    path: '/gerenciar/smartphone-news/tabela-de-preco/cadastrar',
    mount: function mount() {
      return '/gerenciar/smartphone-news/tabela-de-preco/cadastrar';
    }
  },
  tabelaPrecoEditar: {
    path: '/gerenciar/smartphone-news/tabela-de-preco/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/smartphone-news/tabela-de-preco/editar/".concat(id);
    }
  },
  smComparadorAparelhos: {
    path: '/gerenciar/smartphone-news/comparador-de-aparelhos',
    mount: function mount() {
      return "/gerenciar/smartphone-news/comparador-de-aparelhos";
    }
  },
  comparadorAparelhosListar: {
    path: '/gerenciar/smartphone-news/comparador-de-aparelhos/listar',
    mount: function mount() {
      return '/gerenciar/smartphone-news/comparador-de-aparelhos/listar';
    }
  },
  comparadorAparelhosEditar: {
    path: '/gerenciar/smartphone-news/comparador-de-aparelhos/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/smartphone-news/comparador-de-aparelhos/editar/".concat(id);
    }
  },
  comparadorAparelhosCadastrar: {
    path: '/gerenciar/smartphone-news/comparador-de-aparelhos/cadastrar',
    mount: function mount() {
      return "/gerenciar/smartphone-news/comparador-de-aparelhos/cadastrar";
    }
  },
  argumentacaoFDV: {
    path: '/categoria/argumentacao-vendas/:category/:product',
    mount: function mount(category, product) {
      return "/categoria/argumentacao-vendas/".concat(category, "/").concat(product);
    }
  },
  argumentacaoFDVListar: {
    path: '/gerenciar/argumentacao-vendas/listar',
    mount: function mount() {
      return '/gerenciar/argumentacao-vendas/listar';
    }
  },
  argumentacaoFDVEditar: {
    path: '/gerenciar/argumentacao-vendas/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/argumentacao-vendas/editar/".concat(id);
    }
  },
  argumentacaoFDVCadastrar: {
    path: '/gerenciar/argumentacao-vendas/cadastrar',
    mount: function mount() {
      return "/gerenciar/argumentacao-vendas/cadastrar";
    }
  },
  mapaPecas: {
    path: '/categoria/cardapio-smb/:category/:product',
    mount: function mount(category, product) {
      return "/categoria/cardapio-smb/".concat(category, "/").concat(product);
    }
  },
  mapaPecasListar: {
    path: '/gerenciar/mapa-pecas/listar',
    mount: function mount() {
      return '/gerenciar/mapa-pecas/listar';
    }
  },
  mapaPecasEditar: {
    path: '/gerenciar/mapa-pecas/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/mapa-pecas/editar/".concat(id);
    }
  },
  mapaPecasCadastrar: {
    path: '/gerenciar/mapa-pecas/cadastrar',
    mount: function mount() {
      return "/gerenciar/mapa-pecas/cadastrar";
    }
  },
  materiaisApoio: {
    path: '/categoria/materiais-apoio/:category/:product',
    mount: function mount(category, product) {
      return "/categoria/materiais-apoio/".concat(category, "/").concat(product, "/");
    }
  },
  materiaisApoioListar: {
    path: '/gerenciar/materiais-apoio/listar',
    mount: function mount() {
      return '/gerenciar/materiais-apoio/listar';
    }
  },
  materiaisApoioEditar: {
    path: '/gerenciar/materiais-apoio/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/materiais-apoio/editar/".concat(id);
    }
  },
  materiaisApoioCadastrar: {
    path: '/gerenciar/materiais-apoio/cadastrar',
    mount: function mount() {
      return "/gerenciar/materiais-apoio/cadastrar";
    }
  },
  materiaisTimEmpresas: {
    path: '/categoria/:category/:product/materiais-tim-empresas',
    mount: function mount(category, product) {
      return "/categoria/".concat(category, "/").concat(product, "/materiais-tim-empresas");
    }
  },
  materiaisTimEmpresasEditar: {
    path: '/gerenciar/materiais-tim-empresas/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/materiais-tim-empresas/editar/".concat(id);
    }
  },
  materiaisTimEmpresasCadastrar: {
    path: '/gerenciar/materiais-tim-empresas/cadastrar',
    mount: function mount() {
      return "/gerenciar/materiais-tim-empresas/cadastrar";
    }
  },
  materiaisTimEmpresasListar: {
    path: '/gerenciar/materiais-tim-empresas/listar',
    mount: function mount() {
      return '/gerenciar/materiais-tim-empresas/listar';
    }
  },
  bannersListar: {
    path: '/gerenciar/banners/listar',
    mount: function mount() {
      return '/gerenciar/banners/listar';
    }
  },
  bannersCadastrar: {
    path: '/gerenciar/banners/cadastrar',
    mount: function mount() {
      return '/gerenciar/banners/cadastrar';
    }
  },
  bannersEditar: {
    path: '/gerenciar/banners/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/banners/editar/".concat(id);
    }
  },
  relatorios: {
    path: '/gerenciar/relatorios',
    mount: function mount() {
      return '/gerenciar/relatorios';
    }
  },
  relatorioAceites: {
    path: '/gerenciar/relatorios/aceites',
    mount: function mount() {
      return '/gerenciar/relatorios/aceites';
    }
  },
  notificacoes: {
    path: '/notificacoes',
    mount: function mount() {
      return '/notificacoes';
    }
  },
  notificacoesListar: {
    path: '/gerenciar/notificacoes/listar',
    mount: function mount() {
      return '/gerenciar/notificacoes/listar';
    }
  },
  notificacoesCadastrar: {
    path: '/gerenciar/notificacoes/cadastrar',
    mount: function mount() {
      return '/gerenciar/notificacoes/cadastrar';
    }
  },
  notificacoesEditar: {
    path: '/gerenciar/notificacoes/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/notificacoes/editar/".concat(id);
    }
  },
  noticiasConteudo: {
    path: '/noticias/:id',
    mount: function mount(id) {
      return "/noticias/".concat(id);
    }
  },
  atividadesRecentes: {
    path: '/atividades-recentes',
    mount: function mount() {
      return '/atividades-recentes';
    }
  },
  favoritos: {
    path: '/favoritos',
    mount: function mount() {
      return '/favoritos';
    }
  },
  pastaFavoritos: {
    path: '/favoritos/:id',
    mount: function mount(id) {
      return "/favoritos/".concat(id);
    }
  },
  campanhasIncentivoVigentes: {
    path: '/campanhas-incentivo/vigentes',
    mount: function mount() {
      return '/campanhas-incentivo/vigentes';
    }
  },
  campanhasIncentivoVigentesGestor: {
    path: '/campanhas-incentivo/vigentes-gestor/:id',
    mount: function mount(id) {
      return "/campanhas-incentivo/vigentes-gestor/".concat(id);
    }
  },
  listarMetas: {
    path: '/campanhas-incentivo/vigente/:id/metas',
    mount: function mount(id) {
      return "/campanhas-incentivo/vigente/".concat(id, "/metas");
    }
  },
  campanhasIncentivoEncerradas: {
    path: '/campanhas-incentivo/encerradas',
    mount: function mount() {
      return '/campanhas-incentivo/encerradas';
    }
  },
  campanhasIncentivoVigente: {
    path: '/campanhas-incentivo/vigente/:id',
    mount: function mount(id) {
      return "/campanhas-incentivo/vigente/".concat(id);
    }
  },
  campanhasIncentivoVigenteRegulamento: {
    path: '/campanhas-incentivo/vigente/:id/regulamento',
    mount: function mount(id) {
      return "/campanhas-incentivo/vigente/".concat(id, "/regulamento");
    }
  },
  campanhasIncentivoVigenteOld: {
    path: '/campanhas-incentivo-old/vigente/:id',
    mount: function mount(id) {
      return "/campanhas-incentivo-old/vigente/".concat(id);
    }
  },
  campanhasIncentivoEncerrada: {
    path: '/campanhas-incentivo/encerrada/:id',
    mount: function mount(id) {
      return "/campanhas-incentivo/encerrada/".concat(id);
    }
  },
  campanhasIncentivoListar: {
    path: '/gerenciar/campanhas-incentivo/listar',
    mount: function mount() {
      return '/gerenciar/campanhas-incentivo/listar';
    }
  },
  campanhasIncentivoVisualizar: {
    path: '/gerenciar/campanhas-incentivo/visualizar/:id',
    mount: function mount(id) {
      return "/gerenciar/campanhas-incentivo/visualizar/".concat(id);
    }
  },
  campanhasIncentivoEditar: {
    path: '/gerenciar/campanhas-incentivo/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/campanhas-incentivo/editar/".concat(id);
    }
  },
  campanhasIncentivoCadastrar: {
    path: '/gerenciar/campanhas-incentivo/cadastrar',
    mount: function mount() {
      return "/gerenciar/campanhas-incentivo/cadastrar";
    }
  },
  campanhasIncentivoUploadPontos: {
    path: '/gerenciar/campanhas-incentivo/upload-pontos',
    mount: function mount() {
      return "/gerenciar/campanhas-incentivo/upload-pontos";
    }
  },
  campanhasIncentivoExternas: {
    path: '/campanhas-externas',
    mount: function mount() {
      return "/campanhas-externas";
    }
  },
  campanhasIncentivoExternasEditar: {
    path: '/campanhas-externas/editar/:id',
    mount: function mount(id) {
      return "/campanhas-externas/editar/".concat(id);
    }
  },
  campanhasIncentivoApuracao: {
    path: '/gerenciar/campanhas-incentivo/apuracao',
    mount: function mount() {
      return "/gerenciar/campanhas-incentivo/apuracao";
    }
  },
  distribuicaoPontos: {
    path: '/gerenciar/campanhas-incentivo/distribuicao-pontos',
    mount: function mount() {
      return "/gerenciar/campanhas-incentivo/distribuicao-pontos";
    }
  },
  contestacaoListar: {
    path: '/gerenciar/contestacao/listar',
    mount: function mount() {
      return '/gerenciar/contestacao/listar';
    }
  },
  contestacaoEditar: {
    path: '/gerenciar/contestacao/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/contestacao/editar/".concat(id);
    }
  },
  notificacaoBackupListar: {
    path: '/gerenciar/notificacao-backup/listar',
    mount: function mount() {
      return '/gerenciar/notificacao-backup/listar';
    }
  },
  notificacaoBackupEditar: {
    path: '/gerenciar/notificacao-backup/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/notificacao-backup/editar/".concat(id);
    }
  },
  notificacaoBackupCadastrar: {
    path: '/gerenciar/notificacao-backup/cadastrar',
    mount: function mount() {
      return "/gerenciar/notificacao-backup/cadastrar";
    }
  },
  ordemPagamentoCadastrar: {
    path: '/digi/ordem-pagamento/cadastrar',
    mount: function mount() {
      return "/digi/ordem-pagamento/cadastrar";
    }
  },
  ordemPagamentoListar: {
    // esta rota pode ser vista tanto pela equipe digi quanto tim
    path: '/gerenciar/ordem-pagamento/listar',
    mount: function mount() {
      return '/gerenciar/ordem-pagamento/listar';
    }
  },
  ordemPagamentoEditar: {
    path: '/digi/ordem-pagamento/editar/:id',
    mount: function mount(id) {
      return "/digi/ordem-pagamento/editar/".concat(id);
    }
  },
  informacoesExtrato: {
    path: '/informacoes-extrato',
    mount: function mount() {
      return "/informacoes-extrato";
    }
  },
  visualizarInformativo: {
    path: '/visualizar-informativo/:id',
    mount: function mount(id) {
      return "/visualizar-informativo/".concat(id);
    }
  },
  visualizarArquivosCampanha: {
    path: '/visualizar-arquivos-campanha/:idCampaign',
    mount: function mount(idCampaign) {
      return "/visualizar-arquivos-campanha/".concat(idCampaign);
    }
  },
  cadastrarArquivosCampanha: {
    path: '/cadastrar-arquivos-campanha/:idCampaign',
    mount: function mount(idCampaign) {
      return "/cadastrar-arquivos-campanha/".concat(idCampaign);
    }
  },
  editarArquivosCampanha: {
    path: '/editar-arquivos-campanha/:idCampaign/:idFileContent',
    mount: function mount(idCampaign, idFileContent) {
      return "/editar-arquivos-campanha/".concat(idCampaign, "/").concat(idFileContent);
    }
  },
  downloadBases: {
    path: '/download-bases',
    mount: function mount() {
      return "/download-bases";
    }
  },
  importPlanUsers: {
    path: '/importar-planilha-de-usuarios',
    mount: function mount() {
      return "/importar-planilha-de-usuarios";
    }
  },
  changePassword: {
    path: '/open/alterar-senha',
    mount: function mount() {
      return "/open/alterar-senha";
    }
  },
  downloadIncentivo: {
    path: '/download-incentivo',
    mount: function mount() {
      return "/download-incentivo";
    }
  },
  gerenciarPermissoesListar: {
    path: '/gerenciar/permissoes',
    mount: function mount() {
      return "/gerenciar/permissoes";
    }
  },
  gerenciarPermissoesEditar: {
    path: '/gerenciar/permissoes/:id',
    mount: function mount(id) {
      return "/gerenciar/permissoes/".concat(id);
    }
  },
  acompanharContestacao: {
    path: '/acompanhar-contestacao/:id',
    mount: function mount(id) {
      return "/acompanhar-contestacao/".concat(id);
    }
  },
  listUserBlock: {
    path: '/bloqueio-usuario/:userId',
    mount: function mount(userId) {
      return "/bloqueio-usuario/".concat(userId);
    }
  },
  regionais: {
    path: '/veja-mais/:category/:subcategory',
    mount: function mount(category, subcategory) {
      return "/veja-mais/".concat(category, "/").concat(subcategory);
    }
  },
  mapaDeProcessos: {
    path: '/mapa-de-processos',
    mount: function mount() {
      return '/mapa-de-processos';
    }
  },
  mapaDeProcessosListar: {
    path: '/gerenciar/mapa-de-processos/listar',
    mount: function mount() {
      return "/gerenciar/mapa-de-processos/listar";
    }
  },
  mapaDeProcessosEditar: {
    path: '/gerenciar/mapa-de-processos/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/mapa-de-processos/editar/".concat(id);
    }
  },
  mapaDeProcessosCadastrar: {
    path: '/gerenciar/mapa-de-processos/cadastrar',
    mount: function mount() {
      return "/gerenciar/mapa-de-processos/cadastrar/";
    }
  },
  sincronizarGu: {
    path: '/admin-digi/sincronizar-gu',
    mount: function mount() {
      return "/admin-digi/sincronizar-gu/";
    }
  },
  sincronizarCargosGu: {
    path: '/admin-digi/sincronizar-cargos-gu',
    mount: function mount() {
      return "/admin-digi/sincronizar-cargos-gu/";
    }
  },
  gameficationList: {
    path: '/gerenciar/gamificacao-desafios/listar',
    mount: function mount() {
      return "/gerenciar/gamificacao-desafios/listar";
    }
  },
  gameficationEdit: {
    path: '/gerenciar/gamificacao-desafios/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/gamificacao-desafios/editar/".concat(id);
    }
  },
  gameficationCreate: {
    path: '/gerenciar/gamificacao-desafios/cadastrar',
    mount: function mount() {
      return "/gerenciar/gamificacao-desafios/cadastrar";
    }
  },
  gameficationNivelList: {
    path: '/gerenciar/gamificacao-niveis/listar',
    mount: function mount() {
      return "/gerenciar/gamificacao-niveis/listar";
    }
  },
  gameficationNivelEdit: {
    path: '/gerenciar/gamificacao-niveis/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/gamificacao-niveis/editar/".concat(id);
    }
  },
  gameficationNivelCreate: {
    path: '/gerenciar/gamificacao-niveis/cadastrar',
    mount: function mount() {
      return "/gerenciar/gamificacao-niveis/cadastrar";
    }
  },
  gameficationNivelParticipant: {
    path: '/gamificacao-niveis',
    mount: function mount() {
      return "/gamificacao-niveis";
    }
  },
  gameficationNivelCustomList: {
    path: '/gerenciar/gamificacao-niveis-custom/listar',
    mount: function mount() {
      return "/gerenciar/gamificacao-niveis-custom/listar";
    }
  },
  gameficationNivelCustomEdit: {
    path: '/gerenciar/gamificacao-niveis-custom/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/gamificacao-niveis-custom/editar/".concat(id);
    }
  },
  gameficationNivelCustomCreate: {
    path: '/gerenciar/gamificacao-niveis-custom/cadastrar',
    mount: function mount() {
      return "/gerenciar/gamificacao-niveis-custom/cadastrar";
    }
  },
  gameficationResearchesList: {
    path: '/gerenciar/gamificacao-pesquisas/listar',
    mount: function mount() {
      return "/gerenciar/gamificacao-pesquisas/listar";
    }
  },
  gameficationResearchesAnswersList: {
    path: '/gerenciar/gamificacao-pesquisas/respostas/:id',
    mount: function mount(id) {
      return "/gerenciar/gamificacao-pesquisas/respostas/".concat(id);
    }
  },
  gameficationQuizList: {
    path: '/gerenciar/gamificacao-quiz/listar',
    mount: function mount() {
      return "/gerenciar/gamificacao-quiz/listar";
    }
  },
  gameficationQuizAnswersList: {
    path: '/gerenciar/gamificacao-quiz/respostas/:id',
    mount: function mount(id) {
      return "/gerenciar/gamificacao-quiz/respostas/".concat(id);
    }
  },
  gamefication: {
    path: '/gamificacao',
    mount: function mount() {
      return "/gamificacao";
    }
  },
  gameficationMissions: {
    path: '/gamificacao-missoes',
    mount: function mount() {
      return "/gamificacao-missoes";
    }
  },
  gameficationVerificationMissions: {
    path: '/gamificacao-missoes-apuracao/:challengeId',
    mount: function mount(challengeId) {
      return "/gamificacao-missoes-apuracao/".concat(challengeId);
    }
  },
  gameficationRankingParticipant: {
    path: '/gerenciar/gamificacao-ranking',
    mount: function mount() {
      return "/gerenciar/gamificacao-ranking";
    }
  },
  gameficationRankingAdmin: {
    path: '/gerenciar/gamificacao-ranking/:rankingId',
    mount: function mount(rankingId) {
      return "/gerenciar/gamificacao-ranking/".concat(rankingId);
    }
  },
  gameficationRankingList: {
    path: '/gerenciar/gamificacao-ranking/listar',
    mount: function mount() {
      return "/gerenciar/gamificacao-ranking/listar";
    }
  },
  gameficationRankingEdit: {
    path: '/gerenciar/gamificacao-ranking/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/gamificacao-ranking/editar/".concat(id);
    }
  },
  gameficationRankingCreate: {
    path: '/gerenciar/gamificacao-ranking/cadastrar',
    mount: function mount() {
      return "/gerenciar/gamificacao-ranking/cadastrar";
    }
  },
  gameficationChallengePoints: {
    path: '/gerenciar/gamificacao-desafios-pontos/listar',
    mount: function mount() {
      return "/gerenciar/gamificacao-desafios-pontos/listar";
    }
  },
  timTech: {
    path: '/tim-tech',
    mount: function mount() {
      return '/tim-tech';
    }
  },
  timTechInfo: {
    path: '/categoria/informativos/tim_tech',
    mount: function mount() {
      return '/categoria/informativos/tim_tech';
    }
  },
  timTechPrices: {
    path: '/smartphone-news/tabela-de-preco',
    mount: function mount() {
      return '/smartphone-news/tabela-de-preco';
    }
  },
  timTechVideos: {
    path: '/ontube/playlist/tim_tech',
    mount: function mount() {
      return '/ontube/playlist/tim_tech';
    }
  },
  timTechProccess: {
    path: '/categoria/processos/tim_tech',
    mount: function mount() {
      return '/categoria/processos/tim_tech';
    }
  },
  timTechMaterialDeApoio: {
    path: '/categoria/materiais-apoio/cardapio_smb/tim_tech/',
    mount: function mount() {
      return '/categoria/materiais-apoio/cardapio_smb/tim_tech/';
    }
  },
  visualizarRegulamento: {
    path: '/visualizar-regulamento/:id',
    mount: function mount(id) {
      return "/visualizar-regulamento/".concat(id);
    }
  },
  adminMapaOfertas: {
    path: '/novoAdmin/mapaofertas',
    mount: function mount(token) {
      return "/novoAdmin/mapaofertas/login?token=".concat(token);
    }
  },
  comparaAi: {
    path: '/categoria/compara-ai/:category/:product',
    mount: function mount(category, product) {
      return "/categoria/compara-ai/".concat(category, "/").concat(product);
    }
  },
  comparaAiListar: {
    path: '/gerenciar/compara-ai/listar',
    mount: function mount() {
      return '/gerenciar/compara-ai/listar';
    }
  },
  comparaAiEditar: {
    path: '/gerenciar/compara-ai/editar/:id',
    mount: function mount(id) {
      return "/gerenciar/compara-ai/editar/".concat(id);
    }
  },
  comparaAiCadastrar: {
    path: '/gerenciar/compara-ai/cadastrar',
    mount: function mount() {
      return "/gerenciar/compara-ai/cadastrar";
    }
  },
  jornada: {
    path: '/jornada',
    mount: function mount() {
      return '/jornada';
    }
  },
  jornadaInfo: {
    path: '/categoria/informativos/jornada',
    mount: function mount() {
      return '/categoria/informativos/jornada';
    }
  },
  jornadaVideos: {
    path: '/ontube/playlist/jornada',
    mount: function mount() {
      return '/ontube/playlist/jornada';
    }
  },
  jornadaMaterialDeApoio: {
    path: '/categoria/materiais-apoio/cardapio_smb/jornada/',
    mount: function mount() {
      return '/categoria/materiais-apoio/cardapio_smb/jornada/';
    }
  },
  faturaListar: {
    // esta rota pode ser vista tanto pela equipe digi quanto tim
    path: '/gerenciar/fatura/listar',
    mount: function mount() {
      return '/gerenciar/fatura/listar';
    }
  }
};

exports.getRouterByMaterial = function (material, routes) {
  var _a;

  var routeFromMaterial = getRouteFromMaterial(material, routes);
  var fileDownload = (_a = material.files) === null || _a === void 0 ? void 0 : _a.find(findFileDownload);
  return {
    routerAccess: routeFromMaterial,
    routerDownload: fileDownload ? fileDownload.signedUrl : undefined,
    routerEdit: editUrl(material)
  };
};

var editUrl = function editUrl(material) {
  var kind = material.kind,
      _id = material._id;

  switch (kind) {
    case 'communication':
      {
        return exports.consumerRoutes.informativosEditar.mount(_id);
      }

    case 'sales_argument':
      {
        return exports.consumerRoutes.argumentacaoFDVEditar.mount(_id);
      }

    case 'price_table':
      {
        return exports.consumerRoutes.tabelaPrecoEditar.mount(_id);
      }

    case 'tanamao':
      {
        return exports.consumerRoutes.taNaMaoEditar.mount(_id);
      }

    case 'offer_map':
      {
        return exports.consumerRoutes.mapaDeOfertaEditar.mount(_id);
      }

    case 'devices_comparator':
      {
        return exports.consumerRoutes.comparadorAparelhosEditar.mount(_id);
      }
    // case 'see_more': {
    //   return consumerRoutes.vejaMaisOutrosEditar.mount(_id);
    // }

    case 'regulation':
      {
        return exports.consumerRoutes.regulamentoEditar.mount(_id);
      }

    case 'companies_materials':
      {
        return exports.consumerRoutes.materiaisTimEmpresasEditar.mount(_id);
      }

    case 'process':
      {
        return exports.consumerRoutes.processosEditar.mount(_id);
      }

    case 'support_materials':
      {
        return exports.consumerRoutes.materiaisApoioEditar.mount(_id);
      }

    case 'parts_map':
      {
        return exports.consumerRoutes.mapaPecasEditar.mount(_id);
      }

    default:
      {
        return '/';
      }
  }
};

function getRouteFromMaterial(material, routes) {
  var _a, _b, _c, _d;

  switch (material.kind) {
    case 'video':
      return routes.OnTubePlayer.mount(material._id);

    case 'communication':
      var isEmail = (_b = (_a = material) === null || _a === void 0 ? void 0 : _a.communicationItemsConfig) === null || _b === void 0 ? void 0 : _b.find(function (el) {
        return el.kind === 'email';
      });
      if (isEmail) return routes.visualizarInformativo.mount(material._id);
      return getRouterByFileMaterial(material, routes);

    case 'process':
      var routeByFile = getRouterByFileMaterial(material, routes);
      if (routeByFile) return routeByFile;else return routes.conteudoMaterial.mount((_c = material) === null || _c === void 0 ? void 0 : _c._id);

    case 'price_table':
      var filePriceTable = (_d = material.files) === null || _d === void 0 ? void 0 : _d.find(function (item) {
        return item;
      });
      if (filePriceTable && filePriceTable.signedUrl) return filePriceTable.signedUrl;
      return null;

    default:
      return getRouterByFileMaterial(material, routes);
  }
}

function getRouterByFileMaterial(material, routes) {
  var _a, _b, _c, _d, _e, _f, _g;

  if (((_b = (_a = material) === null || _a === void 0 ? void 0 : _a.sentEmail) === null || _b === void 0 ? void 0 : _b.body) || material.html || ((_e = (_d = (_c = material) === null || _c === void 0 ? void 0 : _c.email) === null || _d === void 0 ? void 0 : _d.html) === null || _e === void 0 ? void 0 : _e.body)) return routes.conteudoMaterial.mount(material._id);
  var filePDF = (_f = material.files) === null || _f === void 0 ? void 0 : _f.find(function (item) {
    var _a;

    return ((_a = item) === null || _a === void 0 ? void 0 : _a.extension) === 'pdf';
  });
  if (filePDF) return routes.pdfViewer.mount(material._id);
  var fileImg = (_g = material.files) === null || _g === void 0 ? void 0 : _g.find(function (item) {
    var _a, _b;

    return ((_a = item) === null || _a === void 0 ? void 0 : _a.kind) !== 'cardThumb' && image_extensions_1.isImageExtension(((_b = item) === null || _b === void 0 ? void 0 : _b.extension) || '');
  });
  if (fileImg) return routes.imageViewer.mount(material._id);
  return routes.pdfViewer.mount(material._id);
}

function findFileDownload(item) {
  var _a, _b, _c, _d, _e, _f, _g;

  return ((_a = item) === null || _a === void 0 ? void 0 : _a.extension) !== 'pdf' && !image_extensions_1.isImageExtension(((_b = item) === null || _b === void 0 ? void 0 : _b.extension) || '') && ((_c = item) === null || _c === void 0 ? void 0 : _c.kind) !== 'mailing' && ((_d = item) === null || _d === void 0 ? void 0 : _d.kind) !== 'any_image' && ((_e = item) === null || _e === void 0 ? void 0 : _e.kind) !== 'email_asset' && ((_f = item) === null || _f === void 0 ? void 0 : _f.kind) !== 'video_mp4' && ((_g = item) === null || _g === void 0 ? void 0 : _g.kind) !== 'video_banner';
}