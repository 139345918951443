import { useClient } from '../../autogenerated/client/client';

export function useInvoiceOriginOptions() {
  const data = useClient('ReconheceInvoiceOriginFindMany', {
    fetchOnMount: true,
    cache: true,
  });

  return (data.result?.filter((el) => ['tim', 'tim_coins_expirados', 'incentiveme'].includes(el?._id!)) || []).map(
    (item) => {
      return {
        value: item?._id || '',
        label: item?.name || '',
      };
    },
  );
}
