"use strict";
/**
 * ============================ (c) 2020 Liz Informática ============================
 * @created on Wed Jun 17 2020
 * @author Emir Marques - emirdeliz@gmail.com
 * What is this file?
 * Esse arquivo é responsável por manter as funções úteis relacionadas a um array
 * ==================================================================================
 */

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * @created on Wed Jun 17 2020
 * @author Emir Marques - emirdeliz@gmail.com
 * Esse método serve para pegar os itens de uma interseção entre um array e outro elemento.
 * @param array: array com o conteúdo original aonde terá a lista de valores a ser comparada com o outro elemento.
 * @param el: elemento que pode ou não ser um array. Este será utilizado para verificar se cada item está presente
 * nos dados do primeiro parametro(array). Caso esse parametro não seja um array será feita uma verificacao simples
 * para checar se o valor está presente no array.
 * @param customValidation: validaçao customizada para ser utilizada na interseção
 */

exports.getIntersectionFromTwoArray = function (array, el, customValidation) {
  if (customValidation) {
    return array.filter(function (a) {
      return customValidation(el, a, array);
    });
  }

  if (Array.isArray(el)) {
    return array.filter(function (a) {
      return el.includes(a);
    });
  }

  return array.includes(el) ? [el] : [];
};
/**
 * @created on Wed Jun 17 2020
 * @author Emir Marques - emirdeliz@gmail.com
 * Esse método serve validar se um array contem ao menos um valor que esteja presente em outro array
 * @param array: array com o conteúdo original que geralmente possui mais valores que o segundo array
 * @param array2: array com o conteúdo reduzido que será utilizado para checar se existe algum de seus
 * valores no primerio parametro(array).
 * @param customValidation: validaçao customizada para ser validar os dados entre arrays.
 */


exports.includesAtLeastOneValueFromAnotherArray = function (array, array2, customValidation) {
  return exports.getIntersectionFromTwoArray(array, array2, customValidation).length;
};

function findDuplicateInArray(arra1) {
  var object = {};
  var result = [];
  arra1.forEach(function (item) {
    if (!object[item]) object[item] = 0;
    object[item] += 1;
  });

  for (var prop in object) {
    if (object[prop] >= 2) {
      result.push(prop);
    }
  }

  return result;
}

exports.findDuplicateInArray = findDuplicateInArray;