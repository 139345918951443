import React from 'react';
import { PageTemplate } from '../../../components/Layout/PageTemplate';
import {
  Breadcrumb,
  Button,
  JoystickIconSVG,
  LoadingIndicator,
  Select,
  TitleDescription,
} from '@digi-tim-19/components';
import { routes } from '../../../config/routes';
import { ContainerButtons, FilterContainer, LoadingContainer, NotificationsWrapper, Search } from './styles';
import { useClient } from '../../../autogenerated/client/client';
import { TableChallengePoints } from './TableChallengePoints';
import {
  EnumReconheceWalletStatus,
  EnumUserChallengePointsStatus,
  FilterFindManyUserChallengePointsInput,
} from '../../../autogenerated/client/types';
import { ContainerIcon } from '../../Gamefication/List/styles';
import { usePagination } from '../../../hooks/usePagination';
import { Select as SelectAntd } from 'antd';
import { ModalWalletPoints } from './ModalPoints';

const { Option } = SelectAntd;

const itemsFragment = `
_id
userChallengeId
points
status
challengeNumericId
userInformation {
  name
  CPF
  matricula
  channelName
  regionName
}
challenge {
  title
}
createdAt
updatedAt
`;

export const GameficationChallengePoints = () => {
  const { parsed, loading, pagination, updateFetch } = usePagination('UserChallengePointsPagination', {
    initial: {
      page: 1,
      perPage: 10,
      filter: {
        status: EnumUserChallengePointsStatus.Waiting,
      },
    },
    itemsFragment,
    parseResult: (result) => result,
  });

  const channels = [
    { value: undefined, label: 'TODOS OS CANAIS' },
    { value: 'smb', label: 'SMB' },
    { value: 'mass_channel', label: 'MASS CHANNEL' },
    { value: 'lojas_tim', label: 'LOJAS TIM' },
    { value: 'varejo', label: 'VAREJO' },
    { value: 'retail', label: 'RETAIL' },
  ];

  const regions = [
    { label: 'TODOS AS REGIÕES', value: undefined },
    { label: 'TCN', value: 'TCN' },
    { label: 'THQ', value: 'THQ' },
    { label: 'TNE', value: 'TNE' },
    { label: 'TSE', value: 'TSE' },
    { label: 'TSL', value: 'TSL' },
    { label: 'TSP', value: 'TSP' },
  ];

  const getInvoiceOriginData = useClient('ReconheceWalletFindMany', {
    appendToFragment: `invoiceOriginData { name }`,
    fetchOnMount: true,
    variables: { limit: 1000, filter: { status: EnumReconheceWalletStatus.Available } },
  });

  const [filter, setFilter] = React.useState<FilterFindManyUserChallengePointsInput>({});
  const [usersSelected, setUserSelected] = React.useState<string[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    updateFetch((state) => ({ ...state, filter: { ...state.filter, ...filter } }));
  }, [filter]);

  return (
    <PageTemplate>
      <React.Fragment>
        <NotificationsWrapper>
          <Breadcrumb
            items={[
              { title: 'Home', link: routes.home.mount() },
              { title: 'Gerenciar conteúdo' },
              { title: 'Gamificação (Liberação de TIMCOINS)' },
              { title: 'Listar' },
            ]}
          />
          <TitleDescription
            renderCustomIcon={
              <ContainerIcon>
                <JoystickIconSVG />
              </ContainerIcon>
            }
            title="Gamificação (Liberação de TIMCOINS)"
            description="Listagem dos usuários que aguardam liberação de TIMCOINS"
          />
        </NotificationsWrapper>
        <FilterContainer>
          <Search
            placeholder="Busca título"
            style={{ width: 200 }}
            value={filter.title ?? undefined}
            onChange={({ target }) => setFilter((s) => ({ ...s, title: target.value ? target.value : undefined }))}
          />
          <Search
            placeholder="Busca ID"
            style={{ width: 200 }}
            value={filter.challengeNumericId ?? undefined}
            onChange={({ target }) =>
              setFilter((s) => ({ ...s, challengeNumericId: target.value ? target.value : undefined }))
            }
          />
          <Search
            placeholder="Busca Nome"
            style={{ width: 200 }}
            value={filter.userInformation?.name ?? undefined}
            onChange={({ target }) =>
              setFilter((s) => ({ ...s, userInformation: target.value ? { name: target.value } : undefined }))
            }
          />
          <Search
            placeholder="Busca CPF"
            style={{ width: 200 }}
            value={filter.userInformation?.CPF ?? undefined}
            onChange={({ target }) =>
              setFilter((s) => ({ ...s, userInformation: target.value ? { CPF: target.value } : undefined }))
            }
          />
          <Search
            placeholder="Busca Matricula"
            style={{ width: 200 }}
            value={filter.userInformation?.matricula ?? undefined}
            onChange={({ target }) =>
              setFilter((s) => ({ ...s, userInformation: target.value ? { matricula: target.value } : undefined }))
            }
          />
          <Select
            placeholder="Busca Canal"
            options={channels}
            value={filter.channel ?? undefined}
            onChange={(channelName) => setFilter((s) => ({ ...s, channel: channelName ? channelName : undefined }))}
          />
          <Select
            placeholder="Busca Região"
            options={regions}
            value={filter.userInformation?.regionName ?? undefined}
            onChange={(target) =>
              setFilter((s) => ({ ...s, userInformation: target ? { regionName: target } : undefined }))
            }
          />
          {usersSelected.length > 0 && (
            <ContainerButtons>
              <Button type="primary" onClick={() => setOpen(true)}>
                Liberação
              </Button>
            </ContainerButtons>
          )}
        </FilterContainer>

        <ModalWalletPoints
          open={open}
          setOpen={setOpen}
          userChallengeId={usersSelected}
          setUserSelected={setUserSelected}
          invoiceOrigins={getInvoiceOriginData || []}
        />

        {loading ? (
          <LoadingIndicator />
        ) : (
          <TableChallengePoints
            dataSource={parsed}
            onSelected={setUserSelected}
            invoiceOriginList={getInvoiceOriginData?.data || []}
            pagination={pagination}
          />
        )}
      </React.Fragment>
    </PageTemplate>
  );
};
